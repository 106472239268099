import { Router } from '@angular/router';
import { EventFilterConfig } from '@prism-frontend/components/event-filters/event-filter-config-types';
import { PageContainerStorageKey } from '@prism-frontend/components/page-container-with-filters/page-container-with-filters.component';
import { OrganizationTypeGateService } from '@prism-frontend/services/utils/organization-type-gate.service';
import { PrismHelpArticleKey } from '@prism-frontend/utils/static/getHelpCenterURL';

/**
 * the base about of config needed to configure a page-container-with-filters
 * across multiple URLs.
 */
interface FilterSidebarConfig {
	filterConfig: EventFilterConfig;
	storageKey: string;
	pageTitle: string;
	entityType: string;
	helpArticleKey?: string;
}

export interface FilterRouteConfig extends FilterSidebarConfig {
	helpArticleKey?: PrismHelpArticleKey;
	agentOnlyHelpArticleKey?: PrismHelpArticleKey;
}

/**
 * a base class for services that maintain filter logic across pages
 * this can be used on insights page, reporting pages, or any set of pages
 * where we have multiple sub-routes and pages that have a need to use filters
 */
export abstract class BaseFilterSidebar implements FilterSidebarConfig {
	public abstract filterConfigByRoute: Record<string, FilterRouteConfig>;

	public constructor(private router: Router, private organizationTypeService: OrganizationTypeGateService) {}

	/**
	 * get the angular equivalent of `window.location.pathname`
	 * using Router here makes it easier to write tests
	 *
	 * remove `/send` from pathnames of routes that support it
	 */
	private get currentPath(): string {
		return (this.router.url.split('?')[0] || '').replace('/send', '');
	}

	public get filterConfig(): EventFilterConfig {
		return this.getReportConfigValue('filterConfig') as EventFilterConfig;
	}

	public get storageKey(): PageContainerStorageKey {
		return this.getReportConfigValue('storageKey') as PageContainerStorageKey;
	}

	public get pageTitle(): string {
		return this.getReportConfigValue('pageTitle') as string;
	}

	public get entityType(): string {
		return this.getReportConfigValue('entityType') as string;
	}

	public get helpArticleKey(): PrismHelpArticleKey | undefined {
		const defaultHelpArticleKey: PrismHelpArticleKey | undefined = this.getReportConfigValue(
			'helpArticleKey'
		) as PrismHelpArticleKey;
		const agentHelpArticleKey: PrismHelpArticleKey | undefined = this.getReportConfigValue(
			'agentOnlyHelpArticleKey'
		) as PrismHelpArticleKey;

		if (this.organizationTypeService.isAgent && agentHelpArticleKey) {
			return agentHelpArticleKey;
		}
		return defaultHelpArticleKey;
	}

	private getReportConfigValue(valueToGet: keyof FilterRouteConfig): unknown {
		const currentPath: string = this.currentPath;
		if (!currentPath) {
			throw new Error('no current path found');
		}

		if (!(currentPath in this.filterConfigByRoute)) {
			throw new Error(`no config for route ${currentPath}`);
		}

		return this.filterConfigByRoute[currentPath][valueToGet];
	}
}
