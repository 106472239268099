import { ContextInterpolatorFn } from '@prism-frontend/entities/email-templates/data-interpolation/ContextInterpolatorFn';
import { CustomFieldTemplateData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import {
	customFieldFromRollup,
	emsValueFromRollup,
} from '@prism-frontend/typedefs/ems/ems-static-helpers/emsValueFromRollup';
import { formatFieldValueFromRollup } from '@prism-frontend/typedefs/ems/ems-static-helpers/formatFieldValueFromRollup';
import { EMSCustomField, EMSRollup, PrismEventRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import _ from 'lodash';

export const EMS_FORMATTED_VALUE_INTERPOLATOR: ContextInterpolatorFn<CustomFieldTemplateData> = (
	identifier: string,
	context: CustomFieldTemplateData,
	currencyOverride?: Currency
): string => {
	const [costCalc2, ...rest]: string[] = identifier.split('.');
	const emsPath: keyof EMSFieldDefs = rest.join('.') as keyof EMSFieldDefs;
	const currency: Currency = currencyOverride || (context as EMSRollup)[costCalc2 as CostCalc2].currency;
	return formatFieldValueFromRollup(context as EMSRollup, costCalc2 as CostCalc2, emsPath, currency);
};

export const EMS_UNFORMATTED_VALUE_INTERPOLATOR: ContextInterpolatorFn<CustomFieldTemplateData> = (
	identifier: string,
	context: CustomFieldTemplateData
): string => {
	if (identifier.includes('customField.')) {
		return EMS_CUSTOM_FIELD_INTERPOLATOR(identifier, context);
	}
	return EMS_FIELD_INTERPOLATOR(identifier, context);
};

const EMS_CUSTOM_FIELD_INTERPOLATOR: ContextInterpolatorFn<CustomFieldTemplateData> = (
	identifier: string,
	context: CustomFieldTemplateData
): string => {
	// break identifier which has format: context.customField.customFieldKey
	const parts: string[] = identifier.split('.');
	const customFieldsByKey: Record<string, EMSCustomField> = _.chain(context.customFields).keyBy('key').value();
	const customFieldKey: string = parts[parts.length - 1];
	return customFieldFromRollup(customFieldsByKey, customFieldKey) as string;
};

const EMS_FIELD_INTERPOLATOR: ContextInterpolatorFn<CustomFieldTemplateData> = (
	identifier: string,
	context: CustomFieldTemplateData
): string => {
	return getEMSRollupFieldInterpolation(identifier, context);
};

function getEMSRollupFieldInterpolation(
	identifier: string,
	emsOrPrismEventRollup: Partial<EMSRollup | PrismEventRollup>
): string {
	let value: unknown;
	// break identifier which has format: ems.costCalc2.emsPath
	const [_ems, costCalc2, ...rest]: string[] = identifier.split('.');
	const emsPath: keyof EMSFieldDefs = rest.join('.') as keyof EMSFieldDefs;
	value = emsValueFromRollup(emsOrPrismEventRollup, emsPath, costCalc2 as CostCalc2);
	if (_.isArray(value)) {
		value = value.map((thisValue: unknown): string | number => {
			return JSON.stringify(thisValue, null, 2);
		});
		return `[${(value as []).join(',')}]`;
	}
	return value as string;
}
