import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { InsightsPageService } from '@prism-frontend/pages/insights-page/insights-page.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { Observable, map } from 'rxjs';

/**
 * insights-specific guard. we have a case where users can activate the page if one of the
 * conditions passes, but angular routing requires all guards to pass.
 *
 * as far as i can tell this is the only way to do a nuanced guard
 */
export const CanActivateInsightsGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
): Observable<boolean> => {
	const insightsPageService: InsightsPageService = inject(InsightsPageService);
	const loginService: LoginService = inject(LoginService);

	return loginService.userLoadedStream$.pipe(
		map((): boolean => {
			return insightsPageService.canUserSeeInsights();
		})
	);
};
