import { CostOption } from '@prism-frontend/typedefs/cost';
import { CostGroupType } from '@prism-frontend/typedefs/enums/CostGroupType';

type CostGroupTypeMap = { [key in CostGroupType]: string };

export const CostGroupTypeOptions: CostOption[] = [
	{ label: 'General', value: CostGroupType.General },
	{ label: 'Venue', value: CostGroupType.Venue },
	{ label: 'Promoter', value: CostGroupType.Promoter },
	{ label: 'Talent', value: CostGroupType.Talent },
	{ label: 'Hospitality', value: CostGroupType.Hospitality },
	{ label: 'Travel', value: CostGroupType.Travel },
	{ label: 'Lodging', value: CostGroupType.Lodging },
	{ label: 'Equipment', value: CostGroupType.Equipment },
	{ label: 'Staffing', value: CostGroupType.Staffing },
	{ label: 'Advertising', value: CostGroupType.Advertising },
	{ label: 'Additional', value: CostGroupType.Additional },
	{ label: 'Partner', value: CostGroupType.Partner },
	{ label: 'Merch Expenses', value: CostGroupType.Merch },
	{ label: 'Bar Expenses', value: CostGroupType.Bar },
];

/**
 * these costs types only show up for users in orgs where the IS_BROADWAY feature
 * flag is on
 */
export const BroadwayCostGroupTypeOptions: CostOption[] = [
	{
		label: 'Documented Expenses',
		value: CostGroupType.Documented,
	},
	{
		label: 'Middle Monies',
		value: CostGroupType.MiddleMonies,
	},
	{
		label: 'Fixed Expenses',
		value: CostGroupType.FixedExpenses,
	},
];

export const CostGroupTypeMap: CostGroupTypeMap = [...CostGroupTypeOptions, ...BroadwayCostGroupTypeOptions].reduce(
	(grouping: CostGroupTypeMap, costOption: CostOption): CostGroupTypeMap => {
		grouping[costOption.value] = costOption.label;
		return grouping;
	},
	<CostGroupTypeMap>{}
);
