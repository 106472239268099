import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

interface SnackbarErrorData {
	message: string;
}

@Component({
	selector: 'snackbar-error-component',
	templateUrl: './snackbar-error.component.html',
	styles: [
		`
			:host {
				display: flex;
			}
			.mat-icon {
				padding-right: 30px;
			}
		`,
	],
})
export class SnackbarErrorComponent {
	public message: string;

	public constructor(
		@Inject(MAT_SNACK_BAR_DATA)
		public data: SnackbarErrorData
	) {
		this.message = data?.message || 'There was an error';
	}
}
