import { Cost } from '@prism-frontend/typedefs/cost';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CostGroupCategory } from '@prism-frontend/typedefs/enums/CostGroupCategory';
import { CostGroupType } from '@prism-frontend/typedefs/enums/CostGroupType';
import { plainToClass, Type } from 'class-transformer';
import { IsEnum, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import _ from 'lodash';

export interface CostGroupInterface {
	id: number;
	category: CostGroupCategory;
	type: CostGroupType;
	event_id: number | null;
	tour_id: number | null;
	created_at: string;
	updated_at: string;
}

export type CreateCostGroupInterface = Omit<CostGroupInterface, 'id' | 'created_at' | 'updated_at'>;

export class CostGroup implements CostGroupInterface {
	public constructor(costGroup?: Partial<CostGroup>) {
		return plainToClass(CostGroup, costGroup);
	}

	@IsNumber() public id: number;
	/**
	 * only relevant in the context of event templates
	 */
	@IsString() public uuid?: string;

	@IsNumber() public event_id: number | null;

	@IsNumber() public tour_id: number | null;

	@IsString() public created_at: string;

	@IsString() public updated_at: string;

	@IsEnum(CostGroupCategory) public category: CostGroupCategory;

	@IsEnum(CostGroupType)
	@IsOptional()
	// when adding a new cost group, the type dropdown is empty. The type is validly
	// null unless the user makes a selection
	public type: CostGroupType | null;

	@Type((): typeof Cost => {
		return Cost;
	})
	@ValidateNested()
	public costs: Cost[] = [];

	public get costGroupIdentifier(): string {
		return `${this.category}-${this.type}`;
	}

	public get isAdditionalCostGroup(): boolean {
		return this.type === 'additional costs';
	}

	public get reportedCosts(): Cost[] {
		return this.costs.filter((cost: Cost): boolean => {
			return cost.reported;
		});
	}

	public get nonReportedCosts(): Cost[] {
		return this.costs.filter((cost: Cost): boolean => {
			return !cost.reported;
		});
	}

	public get total_group_cost(): number {
		return this.total(CostCalc.Budgeted, false, false);
	}

	public get actual_total_group_cost(): number {
		return this.total(CostCalc.Actual, false, false);
	}

	public total(costCalc: CostCalc, external: boolean, computeForCoPro: boolean): number {
		if (external && costCalc === CostCalc.Estimated) {
			costCalc = CostCalc.Budgeted;
		}

		return _.sumBy(this.costs, (cost: Cost): number => {
			// do this computation to keep the value out of the total
			if (computeForCoPro && cost.copro_cost_hidden) {
				return 0;
			}

			return cost.totalByCostCalc(costCalc, external, computeForCoPro, false);
		});
	}
}
