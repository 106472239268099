import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import { dumbPluralize } from '@prism-frontend/utils/static/strings';
import moment from 'moment';

interface GetDaysUntilEventArguments {
	includeText: boolean;
}

interface GetDaysUntilEventFormObject {
	includeText: boolean;
}

export function getDaysUntilEventFunctionalChipFactory(
	allowAsText: boolean
): FunctionalChip<GetDaysUntilEventArguments, GetDaysUntilEventFormObject> {
	const getDaysUntilEvent: FunctionalChip<GetDaysUntilEventArguments, GetDaysUntilEventFormObject> = {
		identifier: 'getDaysUntilEvent',
		name: `Days Until/Since Event${allowAsText ? '(allows text)' : ''}`,
		fallbackValue: (): string => {
			return ``;
		},
		mapFormValueForStoring: (value: GetDaysUntilEventFormObject): GetDaysUntilEventArguments => {
			return value;
		},
		theFunction(
			argument: GetDaysUntilEventArguments,
			_orgData: FunctionalChipOrgData,
			data: FunctionalChipAdditionalData
		): FunctionalChipFunctionResult {
			const daysDiff: number = moment(data.firstDate).diff(moment(), 'days');
			if (!argument.includeText) {
				return daysDiff;
			}
			if (daysDiff < 0) {
				return `${Math.abs(daysDiff)} ${dumbPluralize('day', daysDiff)} ago`;
			} else if (daysDiff > 0) {
				return `${daysDiff} ${dumbPluralize('day', daysDiff)} from now`;
			}
			return 'today';
		},
		formFields: (): AllSimpleFormFieldConfigs<GetDaysUntilEventArguments>[] => {
			if (!allowAsText) {
				return [];
			}
			return [
				{
					label: 'Include Text',
					description: 'Format with "days ago" or "days from now" text?',
					fieldType: 'boolean',
					key: 'includeText',
					required: true,
					editable: true,
				},
			];
		},
		chipText: (value: GetDaysUntilEventArguments, _orgData: FunctionalChipOrgData): string => {
			return `Days Until/From Event (${value.includeText ? '(as text)' : '(as number)'})`;
		},
	};
	return getDaysUntilEvent;
}
