import { environment } from '@prism-frontend/../environments/environment';
import { EnvironmentNames } from '@prism-frontend/typedefs/environment.typedef';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const versionJson: { version: string } = require('../../app.version.json');
if (environment.name === EnvironmentNames.Local || environment.name === EnvironmentNames.Dev) {
	versionJson.version = '*';
}
export const VERSION: { version: string } = {
	version: versionJson.version,
};
