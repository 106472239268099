export enum CostGroupType {
	Documented = 'documented',
	General = 'general',
	Venue = 'venue',
	Promoter = 'promoter',
	Talent = 'talent',
	Hospitality = 'hospitality',
	Travel = 'travel',
	Lodging = 'lodging',
	MiddleMonies = 'middle monies',
	Equipment = 'equipment',
	Staffing = 'staffing',
	Advertising = 'ads',
	Additional = 'additional costs',
	Partner = 'partner',
	Merch = 'merch',
	Bar = 'bar',
	FixedExpenses = 'fixed expenses',
}
