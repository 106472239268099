import { EventTicketType } from '@prism-frontend/typedefs/enums/EventTicketType';
import { HeadlinerBadgeItem } from '@prism-frontend/typedefs/headliner-badge-item';
import { plainToClass, Type } from 'class-transformer';
import { IsArray, IsDate, IsEnum, IsInt, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

export class ArtistResearch {
	public constructor(eventResearch?: Partial<ArtistResearch>) {
		return plainToClass(ArtistResearch, eventResearch);
	}

	// not needed for display, but will be convenient for testing and extensibility
	@IsInt()
	public eventId: number = 0;

	@IsArray()
	@ValidateNested()
	@Type((): typeof HeadlinerBadgeItem => {
		return HeadlinerBadgeItem;
	})
	public headliners: HeadlinerBadgeItem[];

	@IsArray()
	@ValidateNested()
	@Type((): typeof HeadlinerBadgeItem => {
		return HeadlinerBadgeItem;
	})
	public supportingArtists: HeadlinerBadgeItem[];

	@IsString()
	public city: string = '';

	@IsInt()
	public capacity: number = 0;

	@IsInt()
	public ticketsSold: number = 0;

	@IsNumber()
	public grossSales: number = 0;

	@IsDate()
	public date: Date;

	@IsString()
	public ticketScaling: string;

	@IsOptional()
	@IsEnum(EventTicketType)
	public ticketIntegration?: EventTicketType;
}
