import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconName } from '@prism-frontend/typedefs/enums/IconName';
import _ from 'lodash';

@Component({
	template: '',
})
export class DialogButtonRowComponentOptsBaseComponent implements OnInit {
	@Input() public buttonLayout: ButtonRowLayoutOptions;
	@Input() public inProgress: boolean;

	@Input() public disableCancel: boolean;
	@Input() public extraClassesCancel: string;
	@Input() public buttonTitleTextCancel: string;
	@Input() public buttonTextCancel: string;
	@Input() public iconCancel: string;

	@Input() public disableConfirm: boolean;
	@Input() public extraClassesConfirm: string;
	@Input() public buttonTitleTextConfirm: string;
	@Input() public buttonTextConfirm: string;
	@Input() public iconConfirm: string;

	public ngOnInit(): void {
		_.defaults(this, DEFAULT_DIALOG_BUTTON_ROW_OPTS);
	}
}

export const DEFAULT_DIALOG_BUTTON_ROW_OPTS: Omit<DialogButtonRowComponentOptsBaseComponent, 'ngOnInit'> = {
	buttonLayout: 'confirm-and-cancel',
	inProgress: false,
	disableCancel: false,
	extraClassesCancel: '',
	buttonTitleTextCancel: '',
	buttonTextCancel: 'Cancel',
	iconCancel: '',
	disableConfirm: false,
	extraClassesConfirm: '',
	buttonTitleTextConfirm: '',
	buttonTextConfirm: 'Done',
	iconConfirm: '',
};

export type DialogButtonRowComponentConfig = Partial<DialogButtonRowComponentOptsBaseComponent>;

export type ButtonRowLayoutOptions =
	| 'confirm-and-cancel'
	| 'confirm-only'
	| 'cancel-only'
	| 'two-secondary'
	| 'no-buttons';

@Component({
	selector: 'dialog-button-row',
	template: `
		<div class="dialog-button-row">
			<div class="button-container">
				<ng-content select="[dialog-extra-buttons]"></ng-content>
				<button
					mat-button
					[ngClass]="['btn', 'cancel-changes', 'secondary-button', 'js-cancel-button', extraClassesCancel]"
					[matTooltip]="buttonTitleTextCancel"
					(click)="cancel.emit()"
					[disabled]="disableCancel || inProgress"
					[attr.aria-label]="buttonTitleTextCancel || buttonTextCancel"
					*ngIf="showCancelButton"
				>
					<span>{{ buttonTextCancel }}</span>
					<mat-icon *ngIf="iconCancel">{{ iconCancel }}</mat-icon>
				</button>

				<button
					mat-button
					[ngClass]="['btn', 'confirm-button', 'primary-button', 'js-confirm-button', extraClassesConfirm]"
					data-testid="js-confirm-button"
					[class.secondary-button-override]="twoSecondaryButtons"
					[class.in-progress]="inProgress"
					[class.visually-disabled]="disableConfirmationButton"
					(click)="!disableConfirmationButton && confirm.emit()"
					*ngIf="showConfirmButton"
					[attr.aria-label]="buttonTitleTextConfirm || buttonTextConfirm"
					[matTooltip]="buttonTitleTextConfirm"
					[disableRipple]="disableConfirmationButton"
				>
					<span class="button-text">
						<span>{{ buttonTextConfirm }}</span>
						<mat-icon *ngIf="iconConfirm">{{ iconConfirm }}</mat-icon>
					</span>
					<prism-icon fill="#fff" [iconName]="loadingIconName"></prism-icon>
				</button>
			</div>
		</div>
	`,
	styleUrls: ['./dialog-button-row.component.scss'],
})
export class DialogButtonRowComponent extends DialogButtonRowComponentOptsBaseComponent {
	public loadingIconName: IconName = IconName.LOADING_ICON;

	// IO which is specific to this component
	@Output() public cancel: EventEmitter<void> = new EventEmitter<void>();
	@Output() public confirm: EventEmitter<void> = new EventEmitter<void>();

	public get showCancelButton(): boolean {
		return this.buttonLayout !== 'confirm-only' && this.buttonLayout !== 'no-buttons';
	}

	public get showConfirmButton(): boolean {
		return this.buttonLayout !== 'cancel-only' && this.buttonLayout !== 'no-buttons';
	}

	public get twoSecondaryButtons(): boolean {
		return this.buttonLayout === 'two-secondary';
	}

	public get disableConfirmationButton(): boolean {
		return this.disableConfirm || this.inProgress;
	}
}
