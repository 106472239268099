import { DocumentNode, gql } from '@apollo/client/core';
import { ArtistFragment } from '@prism-frontend/entities/artists/artist-graphql/artist-graphql-query';
import { AgentUserFragmentOnEvent } from '@prism-frontend/typedefs/graphql/AgentUserFragment';
import { FullDepositFragment } from '@prism-frontend/typedefs/graphql/Deposit';
import { FullSettlementFragment } from '@prism-frontend/typedefs/graphql/Settlement';
import { TalentFragment } from '@prism-frontend/typedefs/graphql/TalentFragment';
import { Talent } from '@prism-frontend/typedefs/talent';
import { TalentData } from '@prism-frontend/typedefs/talentData';

export const RunOfShowFragment: DocumentNode = gql`
	fragment RunOfShowFragment on RunOfShowItem {
		offset
		duration
		event_id
		event_talent_id
		id
		stage_id
		start_time
		title
		relative_to
		include_in_all_docs
	}
`;

const CoreTalentAdjustment: string = `
fragment CoreTalentAdjustment on EventTalentAdjustment {
	id
	name
	amount
	affects_withholding
	affects_artist_earnings
	affects_split_point
	affects_external_settlement_only
	is_artist_bonus_adjustment
}
`;

export const CoreEventTalentFragment: DocumentNode = gql`
	fragment CoreEventTalent on EventTalent {
		id
		artist_earnings_override
		talent_id
		parent_id
		talent_agent_id
		is_fully_settled
		deal_description
		deal_type
		settlement {
			...FullSettlementFragment
		}
		include_in_deal
		withholding_percentage
		withholding_threshold
		withholding_due_days_after
		withholding_status
		withholding_notes
		has_withholding
		has_merch
		hard_merch_artist_percentage
		hard_merch_sold_by
		hard_merch_notes
		headliner
		soft_merch_artist_percentage
		soft_merch_sold_by
		soft_merch_notes
		general_merch_notes
		deal_status
		deal_status_last_updated_at
		deal_tracker_notes
		url_hash
		# would be nice if the backend exposed this
		# updated_at
		# created_at
		guarantee
		retroactive_bonus
		retroactive_after_type
		retroactive_bonuses {
			id
			retroactive_bonus_percentage
			retroactive_after_amount
			bonus_threshold_type
		}
		capped
		cap_amount
		cross_collateralized
		copro_overridden_artist_payout
		copro_artist_payout_last_updated_at
		copro_artist_payout_hidden
		deposits {
			...FullDepositFragment
		}
		bonuses {
			id
			event_talent_id
			type
			after
			amount
			after_amount
			updated_at
			created_at
		}
		balance_payments {
			id
			deal_id
			invoice_id
			created_at
			amount
			exchange_rate_usd
			invoice {
				org_invoice_id
			}
		}
		payout_adjustments {
			...CoreTalentAdjustment
		}
		talent {
			...CoreTalent
		}
		talent_agent {
			...ArtistFragment
		}
		agents {
			...AgentUserFragmentOnEvent
		}
		set_times {
			...RunOfShowFragment
		}
		has_due_date
		contract_due_at
		has_royalty
		royalty_percentage
	}
	${TalentFragment}
	${AgentUserFragmentOnEvent}
	${ArtistFragment}
	${FullDepositFragment}
	${FullSettlementFragment}
	${CoreTalentAdjustment}
	${RunOfShowFragment}
`;

export const FullEventTalentFragment: DocumentNode = gql`
	fragment FullEventTalent on EventTalent {
		...CoreEventTalent
		settlement_terms
		deal_description
		payout_adjustments {
			...CoreTalentAdjustment
			notes
		}
	}
`;

export const TalentSuggestionsQuery: DocumentNode = gql`
	query Talents {
		talents {
			...CoreTalent
		}
	}
	${TalentFragment}
`;

export const UpdateDealOverrideMutation: DocumentNode = gql`
	mutation UpdateDealOverride($id: Int!, $artist_earnings_override: Float) {
		updateDeal(id: $id, artist_earnings_override: $artist_earnings_override) {
			...CoreEventTalent
		}
	}
	${CoreEventTalentFragment}
`;
export interface TalentSuggestionResponse {
	talents: Talent[];
}

export type UpdateDealOverrideArguments = Pick<TalentData, 'id' | 'artist_earnings_override'>;

export interface UpdateDealOverrideResponse {
	updateDeal: Talent;
}
