import { Injectable } from '@angular/core';
import { environment } from '@prism-frontend/../environments/environment';
import Pusher, { Channel } from 'pusher-js';

export interface PusherEventUpdatedAlert {
	orgId: number;
	eventId: number;
	message: {
		userId: number;
		userName: string;
		isDeleted?: boolean;
		softForceReload: boolean; // soft-force reload
		tabsRelevant: string[];
		message: string;
	};
}
export interface PusherHoldUpdatedAlert {
	message: {
		orgId: number;
		holdId: number;
		holdLevel: number;
		clearedByUserId: number;
		clearedByEventId: number;
		message: string;
		cleared: 1 | 0;
	};
}

@Injectable({
	providedIn: 'root',
})
export class PusherService {
	private initialized: boolean = false;
	private pusher: Pusher;
	private channel: Channel;

	public constructor() {
		// Enable console output if debug is on
		Pusher.logToConsole = environment.pusher.debug;

		this.pusher = new Pusher(environment.pusher.app_key, {
			cluster: environment.pusher.cluster,
		});
	}

	/**
	 * Subscribe the current logged in user to the org channel
	 *  Should happen upon logging in
	 * @param orgId
	 */
	public initialize(orgId: number): void {
		// default subscribe to main org channel
		this.channel = this.pusher.subscribe(`org-${orgId}-main`);
		this.initialized = true;
	}

	/**
	 * Listen and respond to an event on the Prism main channel
	 * @param event
	 * @param callback
	 * @param args
	 */
	public bind(event: string, callback: CallableFunction, args?: unknown): void {
		if (!this.initialized) {
			throw new Error('Cannot bind to Pusher: channel not initialized!');
		}
		this.channel.bind(event, callback, args);
	}

	/**
	 * Stop listening to event on the main channel,
	 *  all bindings, or reference to handler
	 * @param event
	 * @param callback
	 */
	public unbind(event: string, callback?: CallableFunction): void {
		if (!this.initialized) {
			throw new Error('Pusher is not initialized!');
		}
		this.channel.unbind(event, callback);
	}
}
