import {
	QueryDslNestedQuery,
	QueryDslQueryContainer,
	QueryDslRangeQuery,
	QueryDslTermsQuery,
	SearchRequest,
} from '@opensearch-project/opensearch/api';
import { defaults } from 'lodash';

class DataInsightsSearchRequest implements SearchRequest {
	public index: string = 'data_share';
}
export class LeaderboardQueryObject extends DataInsightsSearchRequest {
	public body: SearchRequest['body'];
	public constructor(options: {
		extraAggs?: SearchRequest['body']['aggs'];
		mustCriteria?: QueryDslQueryContainer[];
	}) {
		super();
		options = defaults(options, {
			extraAggs: [],
			mustCriteria: [],
		});
		let boolQuery: QueryDslQueryContainer = {};
		if (options.mustCriteria.length > 0) {
			boolQuery = {
				query: {
					bool: {
						must: options.mustCriteria,
					},
				},
			};
		}
		this.body = {
			size: 0,
			...boolQuery,
			aggs: {
				artists_nested: {
					nested: {
						path: 'artists',
					},
					aggs: {
						artist_ids: {
							terms: {
								field: 'artists.spotify_id.keyword',
								size: 300000,
							},
							aggs: {
								total_gross_sales: {
									filter: {
										term: {
											'artists.headliner': true,
										},
									},
									aggs: {
										gross_sales_sum: {
											reverse_nested: {},
											aggs: {
												value: {
													sum: {
														field: 'gross_sales',
													},
												},
											},
										},
										event_count: {
											cardinality: {
												field: '_id',
											},
										},
									},
								},
								average_gross_sales: {
									filter: {
										term: {
											'artists.headliner': true,
										},
									},
									aggs: {
										gross_sales_avg: {
											reverse_nested: {},
											aggs: {
												value: {
													avg: {
														field: 'gross_sales',
													},
												},
											},
										},
									},
								},
								average_capacity: {
									filter: {
										term: {
											'artists.headliner': true,
										},
									},
									aggs: {
										capacity_avg: {
											reverse_nested: {},
											aggs: {
												value: {
													avg: {
														field: 'capacity',
													},
												},
											},
										},
									},
								},
								artist_info: {
									top_hits: {
										size: 1,
										_source: {
											includes: [
												'artists.name',
												'artists.id',
												'artists.spotify_id',
												'artists.images',
												'artists.headliner',
											],
										},
									},
								},
								...options.extraAggs,
								sort_by_average_gross_sales: {
									bucket_sort: {
										sort: [
											{
												'average_gross_sales>gross_sales_avg>value': {
													order: 'desc',
												},
											},
										],
										size: 500,
									},
								},
							},
						},
					},
				},
			},
		};
	}
}

export class ResearchQueryObject extends DataInsightsSearchRequest {
	public body: SearchRequest['body'];
	public constructor(mustCriteria: QueryDslQueryContainer[] = [], maxReturnSize: number = 50000) {
		super();
		let query: QueryDslQueryContainer = {
			match_all: {},
		};
		if (mustCriteria.length > 0) {
			query = {
				bool: {
					must: mustCriteria,
				},
			};
		}
		this.body = {
			size: maxReturnSize,
			query,
		};
	}
}
export class ArtistListQueryObject extends DataInsightsSearchRequest {
	public body: SearchRequest['body'];
	public constructor() {
		super();
		this.body = {
			size: 0,
			aggs: {
				unique_artists: {
					nested: {
						path: 'artists',
					},
					aggs: {
						artist_names: {
							terms: {
								field: 'artists.name.keyword',
								size: 25000,
								order: {
									_key: 'asc',
								},
							},
							aggs: {
								artist_info: {
									top_hits: {
										size: 1,
										_source: {
											includes: ['artists.name', 'artists.images', 'artists.spotify_id'],
										},
									},
								},
							},
						},
					},
				},
			},
		};
	}
}

export class GenreListQueryObject extends DataInsightsSearchRequest {
	public body: SearchRequest['body'];
	public constructor() {
		super();
		this.body = {
			size: 0,
			aggs: {
				all_genres: {
					nested: {
						path: 'artists.genres',
					},
					aggs: {
						genres_names: {
							terms: {
								field: 'artists.genres.name.keyword',
								size: 25000,
								order: {
									_key: 'asc',
								},
							},
							aggs: {
								genre_info: {
									top_hits: {
										size: 1,
										_source: {
											includes: ['artists.genres.name', 'artists.genres.id'],
										},
									},
								},
							},
						},
					},
				},
			},
		};
	}
}
export class VenueCitiesQueryObject extends DataInsightsSearchRequest {
	public body: SearchRequest['body'];
	public constructor() {
		super();
		this.body = {
			size: 0,
			aggs: {
				all_cities: {
					filter: {
						bool: {
							must: [
								{ exists: { field: 'location.city.keyword' } },
								{ exists: { field: 'location.state_short.keyword' } },
							],
						},
					},
					aggs: {
						cities: {
							terms: {
								script: {
									source: `
										String city = doc['location.city.keyword'].size() > 0 ? doc['location.city.keyword'].value : '';
										String state = doc['location.state_short.keyword'].size() > 0 ? doc['location.state_short.keyword'].value : '';
										return city + ', ' + state;
									`,
									lang: 'painless',
								},
								size: 25000,
								order: {
									_key: 'asc',
								},
							},
							aggs: {
								city_info: {
									top_hits: {
										size: 1,
										_source: {
											includes: ['location.city', 'location.state_short'],
										},
									},
								},
							},
						},
					},
				},
			},
		};
	}
}
export class VenueStatesListQueryObject implements SearchRequest {
	public body: SearchRequest['body'];
	public constructor() {
		this.body = {
			size: 0,
			aggs: {
				states: {
					terms: {
						field: 'location.state.keyword',
						size: 25000,
						order: {
							_key: 'asc',
						},
					},
				},
			},
		};
	}
}
export class CapacityRangeQueryObject extends DataInsightsSearchRequest {
	public body: SearchRequest['body'];
	public constructor() {
		super();
		this.body = {
			size: 0,
			aggs: {
				artists_nested: {
					nested: {
						path: 'artists',
					},
					aggs: {
						overall_capacity: {
							reverse_nested: {},
							aggs: {
								min_capacity: {
									min: {
										field: 'capacity',
									},
								},
								max_capacity: {
									max: {
										field: 'capacity',
									},
								},
							},
						},
					},
				},
			},
		};
	}
}
export class AverageCapacityRangeQueryObject extends DataInsightsSearchRequest {
	public body: SearchRequest['body'];
	public constructor() {
		super();
		// this pulls a lot more data than it needs, but it seems necessary to get the average min/max capacity
		this.body = {
			size: 0,
			aggs: {
				unique_artists: {
					nested: {
						path: 'artists',
					},
					aggs: {
						artist_names: {
							terms: {
								field: 'artists.name.keyword',
								size: 25000,
								order: {
									_key: 'asc',
								},
							},
							aggs: {
								artist_info: {
									top_hits: {
										size: 1,
										_source: {
											includes: ['artists.name', 'artists.images', 'artists.spotify_id'],
										},
									},
								},
								average_capacity: {
									filter: {
										term: {
											'artists.headliner': true,
										},
									},
									aggs: {
										reverse_nested: {
											reverse_nested: {},
											aggs: {
												capacity_avg: {
													avg: {
														field: 'capacity',
													},
												},
											},
										},
									},
								},
							},
						},
						average_capacity_stats: {
							stats_bucket: {
								buckets_path: 'artist_names>average_capacity>reverse_nested>capacity_avg',
							},
						},
					},
				},
			},
		};
	}
}

export class ArtistFilterObject implements QueryDslQueryContainer {
	public nested: QueryDslNestedQuery;

	public constructor(artistSpotifyIds: string[]) {
		this.nested = {
			path: 'artists',
			query: {
				bool: {
					must: [
						{
							terms: {
								'artists.spotify_id.keyword': artistSpotifyIds,
							},
						},
					],
				},
			},
		};
	}
}

export class DateRangeFilterObject implements QueryDslQueryContainer {
	public range: {
		date: QueryDslRangeQuery & { format: string };
	};

	public constructor(startDate: string, endDate: string) {
		this.range = {
			date: {
				gte: startDate,
				lte: endDate,
				format: 'yyyy-MM-dd HH:mm:ss',
			},
		};
	}
}

export class DaysOfWeekFilterObject implements QueryDslQueryContainer {
	public script: {
		script: {
			source: string;
			lang: 'painless';
			params: {
				days: number[];
			};
		};
	};

	public constructor(daysOfWeek: number[]) {
		this.script = {
			script: {
				source: `
                    def date = doc['date'].value;
                    def dayOfWeek = date.dayOfWeek;
                    return params.days.contains(dayOfWeek);
                `,
				lang: 'painless',
				params: {
					days: daysOfWeek,
				},
			},
		};
	}
}
export class MonthsOfYearFilterObject implements QueryDslQueryContainer {
	public script: {
		script: {
			source: string;
			lang: string;
			params: {
				months: number[];
			};
		};
	};

	public constructor(monthsOfYear: number[]) {
		this.script = {
			script: {
				source: `
                    def date = doc['date'].value;
                    def monthOfYear = date.monthOfYear;
                    return params.months.contains(monthOfYear);
                `,
				lang: 'painless',
				params: {
					months: monthsOfYear,
				},
			},
		};
	}
}

export class VenueCityFilterObject implements QueryDslQueryContainer {
	public terms: QueryDslTermsQuery & { 'location.city.keyword': string[] };

	public constructor(cities: string[]) {
		this.terms = {
			'location.city.keyword': cities,
		};
	}
}
export class VenueStateShortFilterObject implements QueryDslQueryContainer {
	public terms: QueryDslTermsQuery & { 'location.state_short.keyword': string[] };

	public constructor(states: string[]) {
		this.terms = {
			'location.state_short.keyword': states,
		};
	}
}

export class VenueStateFilterObject implements QueryDslQueryContainer {
	public terms: QueryDslTermsQuery & { 'location.state.keyword': string[] };

	public constructor(states: string[]) {
		this.terms = {
			'location.state.keyword': states,
		};
	}
}

export class GenreFilterObject implements QueryDslQueryContainer {
	public nested: QueryDslNestedQuery;

	public constructor(genreIds: number[]) {
		this.nested = {
			path: 'artists',
			query: {
				bool: {
					must: [
						{
							nested: {
								path: 'artists.genres',
								query: {
									bool: {
										must: [
											{
												terms: {
													'artists.genres.id': genreIds,
												},
											},
										],
									},
								},
							},
						},
					],
				},
			},
			inner_hits: {},
		};
	}
}
export class ResearchCapacityFilterObject implements QueryDslQueryContainer {
	public range: {
		capacity: QueryDslRangeQuery;
	};

	public constructor(minCapacity: number, maxCapacity: number) {
		this.range = {
			capacity: {
				gte: minCapacity,
				lte: maxCapacity,
			},
		};
	}
}
