import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { EventFeeType } from '@prism-frontend/typedefs/enums/EventFeeType';
import { OrderedModel } from '@prism-frontend/typedefs/enums/OrderedModel';
import { EventFee } from '@prism-frontend/typedefs/eventFee';
import { Orderable } from '@prism-frontend/typedefs/orderable';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToInstance, Transform, Type } from 'class-transformer';
import { IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

/**
 * Interface that defines the minimum required methods for a ticket
 * to be used in the math downstream. All tickets should implement
 * this interface, currently the main Ticket and FlatTicket class
 */
export interface TicketCalculation {
	/**
	 * Calculates the revenue for this ticket
	 * @param costCalc cost calc being used
	 */
	ticketRevenue(costCalc: CostCalc): number;
	/**
	 * Calculates the ticket net gross amount
	 * @param costCalc cost calc being used
	 * @param eventProps event properties for ticket calculations
	 */
	netGross(costCalc: CostCalc, eventProps: EventPropsForTicket): number;
	/**
	 * Calculates the ticket adjusted gross amount
	 * @param costCalc cost calc being used
	 * @param eventProps event properties for ticket calculations
	 */
	adjustedGross(costCalc: CostCalc, eventProps: EventPropsForTicket): number;
	/**
	 * Calculates the total tax amount for this ticket
	 * @param costCalc cost calc being used
	 * @param eventProps event properties for ticket calculations
	 */
	totalTax(costCalc: CostCalc, eventProps: EventPropsForTicket): number;
	/**
	 * Calculates the adjusted gross for this ticket considering event fees
	 * that should apply to the ticket.
	 * @param eventProps event properties for ticket calculations
	 * @param adjustedGross The adjusted gross for this ticket
	 */
	calculateAdjustedGrossFees(eventProps: EventPropsForTicket, adjustedGross: number): number;
	/**
	 * Calculates the total amount of event fees computed for this ticket
	 * @param costCalc cost calc being used
	 * @param eventProps event properties for ticket calculations
	 * @param onlyPreSettlementFees indicated which kind of fees should be computed, if true
	 * we will only take int account PERCENT_OF_GROSS event fees type besides the particular
	 * ticket fees included. If false we will also include PERCENT_OF_ADJUSTED_GROSS event fees type
	 * which are considered after presettlement fees and taxes
	 */
	totalEventFees(costCalc: CostCalc, eventProps: EventPropsForTicket, onlyPreSettlementFees: boolean): number;
}

export abstract class TicketModel {
	public calculateAdjustedGrossFees(eventProps: EventPropsForTicket, adjustedGross: number): number {
		const totalAdjustedGross: number = eventProps.allEventFees.reduce(
			(previousAdjustedGross: number, eventFee: EventFee): number => {
				if (eventFee.type !== EventFeeType.PERCENT_OF_ADJUSTED_GROSS) {
					return previousAdjustedGross;
				}
				return previousAdjustedGross + eventFee.computeAdjustedGrossFee(adjustedGross);
			},
			0
		);

		return totalAdjustedGross;
	}
}

interface GrossPieces {
	netGross: number;
	adjustedGross: number;
}

export interface EventPropsForTicket {
	facilityFee: number;
	withoutTaxMultiplier: number;
	allEventFees: EventFee[];
}

class TicketBackendModel extends TicketModel implements Orderable {
	public readonly modelName: OrderedModel = OrderedModel.Ticket;

	@IsNumber() public id: number = null;
	/**
	 * only relevant in the context of event templates
	 */
	@IsString() public uuid?: string;

	@IsString() public name: string = '';

	@IsNumber() public allotment: number = 0;

	@IsNumber() public comps: number = 0;

	@IsNumber() public kills: number = 0;

	@IsNumber() public ticket_price: number = 0;

	@IsNumber() public sold: number = 0;

	@IsNumber() public est_sold: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	protected platform_total: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	protected platform_tax_paid: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	protected platform_discounts: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	protected platform_rebate: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	public platform_fees: number = 0;

	@IsNumber()
	@Transform(castToNumber(true))
	public order: number;

	@Type((): typeof EventFee => {
		return EventFee;
	})
	@ValidateNested()
	public event_fees: EventFee[] = [];

	// Make this private to avoid trying to read it directly
	@IsString()
	@IsOptional()
	protected platform_id: string | null = null;
}

export class Ticket extends TicketBackendModel implements TicketCalculation {
	public constructor(ticket?: Partial<Ticket>) {
		super();
		return plainToInstance(Ticket, ticket);
	}

	public static getEventFeesForTicket(
		ticket: Ticket,
		eventFees: EventFee[],
		onlyPreSettlementFees: boolean
	): EventFee[] {
		return eventFees.filter((eventFee: EventFee): boolean => {
			if (eventFee.type === EventFeeType.PERCENT_OF_ADJUSTED_GROSS && onlyPreSettlementFees) {
				return false;
			}

			if (
				eventFee.type === EventFeeType.FLAT_OUT_OF_GROSS ||
				eventFee.type === EventFeeType.FLAT_OUT_OF_ADJUSTED_GROSS
			) {
				return false;
			}

			if (eventFee.ticket_id === null) {
				return true;
			}

			return eventFee.ticket_id === ticket.id;
		});
	}

	/**
	 * The JSON encoder on the back end converts the VARCHAR platform_id
	 * into a pure number type. Some of the ids are very long and out of
	 * range for the Javascript number type. When the number type hits JS
	 * it will often get changed. The solution, at least until we figure this bug out
	 * is to add the '*' to the platform_id, which forces laravel to encode the value
	 * as a string. In order to access the pure ID on the front end, we can chop the
	 * asterisk
	 */
	public get platformIdString(): string | null {
		if (this.platform_id) {
			return this.platform_id.replace('*', '');
		}
		return null;
	}

	public get sellable(): number {
		return this.allotment - this.comps - this.kills;
	}

	public get platformTotal(): number {
		return +this.platform_total;
	}

	public get platformTaxPaid(): number {
		return +this.platform_tax_paid;
	}

	private get didPayPlatformTax(): boolean {
		return !!this.platformIdString && !!this.platformTaxPaid;
	}

	public get platformDiscounts(): number {
		return +this.platform_discounts;
	}

	public platformRebate(costCalc: CostCalc): number {
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				return 0;
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.platform_rebate ? +this.platform_rebate : 0;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	public get platformFees(): number {
		return +this.platform_fees;
	}

	public ticketsSold(costCalc: CostCalc): number {
		switch (costCalc) {
			case CostCalc.Reported:
			case CostCalc.Actual:
				return +this.sold;
			case CostCalc.Budgeted:
			case CostCalc.Potential:
				return +this.sellable;
			case CostCalc.Estimated:
				return +this.est_sold;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	public totalEventFees(costCalc: CostCalc, eventProps: EventPropsForTicket, onlyPreSettlementFees: boolean): number {
		const relevantEventFees: EventFee[] = Ticket.getEventFeesForTicket(
			this,
			eventProps.allEventFees,
			onlyPreSettlementFees
		);
		let totalEventFees: number = 0;
		relevantEventFees.forEach((eventFee: EventFee): void => {
			totalEventFees += eventFee.eventFee(costCalc, [this], [], eventProps);
		});

		return totalEventFees;
	}

	public totalFacilityFee(costCalc: CostCalc, eventProps: EventPropsForTicket): number {
		return this.ticketsSold(costCalc) * eventProps.facilityFee;
	}

	public totalTicketFees(
		costCalc: CostCalc,
		eventProps: EventPropsForTicket,
		onlyPreSettlementFees: boolean
	): number {
		const totalFacilityFee: number = this.totalFacilityFee(costCalc, eventProps);
		const totalEventFees: number = this.totalEventFees(costCalc, eventProps, onlyPreSettlementFees);
		return totalFacilityFee + totalEventFees;
	}

	private grossPieces(costCalc: CostCalc, eventProps: EventPropsForTicket): GrossPieces {
		// gross = ticket revenue + fees
		// adjustedGross = gross - fees
		// for adjusted gross only consider pre-settlement fees
		const adjustedGross: number = this.ticketRevenue(costCalc) - this.totalTicketFees(costCalc, eventProps, true);

		let netGross: number = adjustedGross;
		let shouldRemovePlatformTax: boolean = false;
		switch (costCalc) {
			case CostCalc.Reported:
			case CostCalc.Actual:
				if (this.didPayPlatformTax) {
					shouldRemovePlatformTax = true;
					// adjustedGross = this.platformTotal;
				}
				break;
			case CostCalc.Budgeted:
			case CostCalc.Potential:
			case CostCalc.Estimated:
				break;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}

		// netGross = adjustedGross - taxes
		if (shouldRemovePlatformTax) {
			netGross = netGross - this.platformTaxPaid;
		} else {
			netGross = netGross * eventProps.withoutTaxMultiplier;
		}

		netGross -= this.calculateAdjustedGrossFees(eventProps, adjustedGross);

		return {
			// adjustedGross minus tax minus fees
			netGross,
			adjustedGross,
		};
	}

	public netGross(costCalc: CostCalc, eventProps: EventPropsForTicket): number {
		return this.grossPieces(costCalc, eventProps).netGross;
	}

	public adjustedGross(costCalc: CostCalc, eventProps: EventPropsForTicket): number {
		return this.grossPieces(costCalc, eventProps).adjustedGross;
	}

	public totalTax(costCalc: CostCalc, eventProps: EventPropsForTicket): number {
		if (this.didPayPlatformTax) {
			return 0;
		}
		const { adjustedGross, netGross }: GrossPieces = this.grossPieces(costCalc, eventProps);
		return adjustedGross - netGross - this.calculateAdjustedGrossFees(eventProps, adjustedGross);
	}

	public get fee_deducted(): boolean {
		return this.sold * this.ticket_price - this.platformFees === this.platformTotal && this.platformFees !== 0;
	}

	/**
	 *  Get the gross ticket price for platform sales
	 */
	public platformGrossTicketPrice(costCalc: CostCalc): number {
		if (!this.platformIdString) {
			return 0;
		}

		const ticketTotal: number = this.ticketRevenue(costCalc);
		const ticketsSold: number = this.ticketsSold(costCalc);

		return ticketsSold > 0 ? ticketTotal / ticketsSold : this.ticket_price;
	}

	/**
	 * get the net price of this ticket, which is raw ticket price minus fees per ticket
	 *
	 * @param costCalc cost calc being used
	 * @param eventProps event properties required for ticket calculations
	 * @returns ticket_price - feesPerTicket()
	 */
	public netTicketPrice(eventProps: EventPropsForTicket): number {
		return this.ticket_price - this.feesPerTicket(eventProps);
	}

	/**
	 * calculate all event fees and facility fees per-ticket. this value is used to compute the net price of a ticket
	 *
	 * note that this does not include fees that are % of adjusted gross. adjusted gross fees get removed after taxes
	 * and all other fees are removed
	 *
	 * @param costCalc cost calc being used
	 * @param eventProps event properties required for ticket calculations
	 * @returns the total of all event and facility fees that apply, per ticket
	 */
	public feesPerTicket(eventProps: EventPropsForTicket): number {
		// for ticket fees consider only pre-settlement fees
		const relevantEventFees: EventFee[] = Ticket.getEventFeesForTicket(this, eventProps.allEventFees, true);
		const perTicketEventFees: number = relevantEventFees.reduce(
			(totalPerTicketFees: number, fee: EventFee): number => {
				return totalPerTicketFees + fee.perTicketEventFee(this);
			},
			0
		);
		const perTicketFacilityFee: number = eventProps.facilityFee;

		return perTicketEventFees + perTicketFacilityFee;
	}

	/**
	 * ticketRevenue, also known as ticket gross
	 *
	 * @param costCalc cost calc being used
	 * @returns raw ticket gross
	 */
	public ticketRevenue(costCalc: CostCalc): number {
		const honorPlatformTotals: boolean =
			(costCalc === CostCalc.Reported || costCalc === CostCalc.Actual) && !!this.platformIdString;
		if (honorPlatformTotals) {
			return this.platformTotal + this.platformTaxPaid;
		}

		return this.ticket_price * this.ticketsSold(costCalc);
	}
}
