import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PRISM_PURPLE } from '@prism-frontend/utils/static/colors';
import _, { isNil } from 'lodash';

/**
 * easily get a loading icon while wrapping your content
 */
@Component({
	selector: 'loading-container',
	template: `
		<div class="loading-container" data-testid="js-loading-container">
			<div
				class="loading-content"
				[class.show]="loading"
				[class.fadeOut]="!loading"
				[@hide]="!loading"
				[style.--loadingContentTransitionDelay]="transitionDelayStyle"
			>
				<h3 class="loading-text">
					{{ loadingText }}...
					<prism-icon [fill]="PRISM_PURPLE" iconName="loading"></prism-icon>
				</h3>

				<div class="progress-bar-container">
					<mat-progress-bar [mode]="loaderMode" [value]="progress"></mat-progress-bar>
				</div>
			</div>
			<div class="content-container" [class.fadeOut]="loading">
				<ng-content></ng-content>
			</div>
		</div>
	`,
	styleUrls: ['./loading-container.component.scss'],
	animations: [
		// this is to transition the loading container to block/none
		trigger('hide', [
			state('true', style({ height: '0', margin: '0' })),
			state('false', style({ height: '100%' })),
			transition('false => true', [animate('0ms 250ms ease')]),
			transition('true => false', [animate('0ms 0ms ease')]),
		]),
	],
})
export class LoadingContainerComponent implements OnChanges {
	public PRISM_PURPLE: string = PRISM_PURPLE;

	@Input() public loading: boolean = false;
	@Input() public loadingText: string = 'Loading';

	// a number between 0 and 100
	@Input() public progress: number | undefined;

	/**
	 * use if you wish to delay the "Loading" text for an interval of time
	 * an example use case is if you are working with a particularly fast API,
	 * the loading text wont flash on the screen for a split second
	 */
	@Input() public delayLoadingTextMS: number = 250;

	public loaderMode: 'determinate' | 'indeterminate' = 'indeterminate';

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.progress) {
			if (_.isNumber(this.progress)) {
				this.loaderMode = 'determinate';
			} else {
				this.loaderMode = 'indeterminate';
			}
		}
		if (changes.loadingText) {
			this.loadingText = this.loadingText || 'Loading';
		}
	}

	public get transitionDelayStyle(): string {
		if (isNil(this.delayLoadingTextMS)) {
			return undefined;
		}
		return `${this.delayLoadingTextMS}ms`;
	}
}
