import { plainToClass } from 'class-transformer';
import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';

export class HeadlinerBadgeItem {
	public constructor(headlinerBadgeItem?: Partial<HeadlinerBadgeItem>) {
		return plainToClass(HeadlinerBadgeItem, headlinerBadgeItem);
	}
	@IsString()
	@IsOptional()
	public imageSrc: string | undefined;
	@IsBoolean()
	public isHeadliner: boolean;
	@IsString()
	public name: string;
	@IsNumber()
	@IsOptional()
	public artistId: number | null;
}
