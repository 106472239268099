import {
	AllCustomFieldConfigs,
	AllCustomFieldTypes,
	CustomFieldEventBackend,
	customFieldKey,
	CustomFieldProcessType,
	CustomFieldsById,
	flattenCustomFieldType,
} from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import {
	castCustomValue,
	customFieldDisplayValue,
} from '@prism-frontend/entities/custom-fields/static/castCustomValue';
import {
	fetchOptionsForComputedCustomFields,
	fetchOptionsForComputedEditableCustomFields,
	fetchOptionsForTemplatedStringCustomFields,
} from '@prism-frontend/entities/email-templates/data-interpolation/fetchOptionsForComputedCustomFields';
import { interpolateValuesIntoString } from '@prism-frontend/entities/email-templates/data-interpolation/interpolateValuesIntoString';
import { performCalculation } from '@prism-frontend/entities/email-templates/data-interpolation/performCalculation';
import { ADVANCED_CUSTOM_FIELD_INTERPOLATORS } from '@prism-frontend/pages/testing-page/components/field-sandbox/ADVANCED_CUSTOM_FIELD_INTERPOLATORS';
import { FieldSandboxTemplateContext } from '@prism-frontend/pages/testing-page/components/field-sandbox/FieldSandboxTemplateContext';
import { CustomFieldTemplateData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import {
	EMSCustomField,
	EMSHydrationAddons,
	EMSRollup,
	PrismEventRollup,
} from '@prism-frontend/typedefs/ems/ems-typedefs';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { normalizeEventCustomFieldValuesTimer } from '@prism-frontend/utils/static/custom-fields/normalizeEventCustomFieldValuesTimer';
import { scrubCustomFields } from '@prism-frontend/utils/static/custom-fields/scrubCustomFields';
import _ from 'lodash';

/**
 * Process the ems custom fields for a certain event. To do this we need to normalize
 * all custom fields from the hydration addons and the event custom fields. We need to
 * do this initally for basic types and only then we can process advance types which
 * can be made up of basic custom fields, so we need to have their value first to process
 * the EMSCustomField. We take ExternalReported as the reference costCalc for the custom fields
 *
 * @param emsRollup The partial EMSRollup
 * @param costCalc2 The current CostCalc
 * @param event The PrismEvent
 * @param hydrationAddons The hydration addons containing customFieldData
 * @param customFieldsToProcess the type of custom fields to process
 * @returns The list of EMSCustomFields
 */
export function mapHydratationAddonsToEMSCustomFields(
	emsRollup: EMSRollup,
	event: PrismEvent,
	hydrationAddons: EMSHydrationAddons
): EMSCustomField[] {
	const rollupCopy: EMSRollup = { ...emsRollup };
	// grab the custom fields based on the organization and event custom fields
	// first process and set basic custom fields over the rollup (non advanced ones)
	// for each hydratated custom field map it to an EMSCustomField and set the result
	// to the rollup copy for ExternalReported which is the costCalc used as reference
	rollupCopy.customFields = _.values(
		normalizeEventCustomFieldValues(rollupCopy, event.custom_fields, hydrationAddons, 'basic')
	).map((customField: AllCustomFieldConfigs, index: number): EMSCustomField => {
		return mapCustomFieldConfigToEMSCustomField(event, CostCalc2.ExternalReported, customField, index);
	});
	// once the basic custom fields are loaded on the ems we can
	// process all advanced custom fields which for getting their actual value
	// we rely on basic custom fields which should already be loaded.
	rollupCopy.customFields = _.values(
		normalizeEventCustomFieldValues(rollupCopy, event.custom_fields, hydrationAddons)
	).map((customField: AllCustomFieldConfigs, index: number): EMSCustomField => {
		return mapCustomFieldConfigToEMSCustomField(event, CostCalc2.ExternalReported, customField, index);
	});

	return rollupCopy.customFields;
}

/**
 * Maps a particular custom field config to an EMSCustomField
 * @param event The PrismEvent
 * @param costCalc2 The current CostCalc
 * @param customField The custom field config
 * @param index The index of the custom field for arranging the emsPath
 * @returns The EMSCustomField for the particular costCalc
 */
function mapCustomFieldConfigToEMSCustomField(
	event: PrismEvent,
	costCalc2: CostCalc2,
	customField: AllCustomFieldConfigs,
	index: number
): EMSCustomField {
	return {
		key: customFieldKey(customField),
		costCalc: costCalc2,
		description: customField.description,
		id: customField.id,
		emsMetadataId: `customFields.*`,
		emsPath: `customFields.${index}`,
		rawValue: customField.event_value,
		stringValue: customFieldDisplayValue(customField, event),
		label: customField.name,
		parentId: customField.custom_field_id,
		type: customField.type,
		flatType: flattenCustomFieldType(customField),
		isAdvanced: !!customField.is_advanced,
	};
}

/**
 * This function "zips" together (1) the organization-level custom field data
 * and (2), the event-level custom field data.
 *
 * It is responsible for creating the "complete" event level data
 * model representing all of the organization's custom fields, and their
 * event-level values.
 *
 * This is necessary because the backend only returns event-level custom
 * fields for which an explicit value has been set by the user (which can
 * happen when the field is created via the "Apply The Default Value To All
 * Existing Events", OR by directly editing the custom field's value on the
 * event settings page). Note that for advanced custom fields, the backend will
 * NEVER return a field at the event level, because we use the default_value
 * to dynamically compute the event level-value for the field after we have
 * generated the rest of the EMS.
 *
 * @param ems
 * @param event
 * @param eventCustomValuesList
 * @param hydrationAddons
 * @param customFieldsToProcess
 * @returns
 */
export function normalizeEventCustomFieldValues(
	/**
	 * The partial EMSRollup (that will not contain any custom fields data)
	 */
	ems: EMSRollup,
	eventCustomValuesList: CustomFieldEventBackend[],
	hydrationAddons: EMSHydrationAddons,
	customFieldsToProcess: CustomFieldProcessType = 'all'
): CustomFieldsById<AllCustomFieldConfigs> {
	if (!hydrationAddons.customFieldData) {
		throw new Error('hydrationAddons must contain customFieldData!');
	}
	// Build a full list of the organization's custom fields
	const orgCustomFields: CustomFieldsById = scrubCustomFields(
		hydrationAddons.customFieldData.orgCustomFields,
		customFieldsToProcess
	) as CustomFieldsById;
	normalizeEventCustomFieldValuesTimer.start();
	const eventCustomFields: CustomFieldsById<AllCustomFieldConfigs> = scrubCustomFields(
		eventCustomValuesList,
		customFieldsToProcess
	) as Record<number, AllCustomFieldConfigs>;
	const missing: number[] = _.difference(
		Object.keys(orgCustomFields),
		Object.keys(eventCustomFields)
	) as unknown as number[];
	// append any items that were not explicitly set at the event level
	// what happens with event custom fields that are not part of the org custom fields?
	// we now fetch options based on the hydratationAddons but we could be missing options?
	missing.forEach((customFieldId: number): void => {
		const config: AllCustomFieldConfigs = {
			...orgCustomFields[customFieldId],
			event_id: ems.id,
			event_value: castCustomValue(undefined, orgCustomFields[customFieldId]) as unknown as string,
		} as AllCustomFieldConfigs;
		const flatType: AllCustomFieldTypes = flattenCustomFieldType(config);
		const contextTemplateData: CustomFieldTemplateData = ems;
		const context: FieldSandboxTemplateContext = {
			context: contextTemplateData,
			functionalChipsContext: {
				...contextTemplateData,
				event: ems.event,
			},
		};
		switch (flatType) {
			case 'advanced_computed':
			case 'advanced_computed_currency':
				config.event_value = interpolateValuesIntoString<
					FieldSandboxTemplateContext,
					FieldSandboxTemplateContext
				>(
					config.default_value as string,
					context,
					fetchOptionsForComputedCustomFields(hydrationAddons.customFieldData.orgCustomFields),
					{
						rolesById: hydrationAddons.customFieldData.rolesById,
						isAdminModeEnabled: hydrationAddons.customFieldData.isAdminModeEnabled,
						isBroadway: hydrationAddons.customFieldData.isBroadway,
					},
					// TODO PRSM-9274 keep this using an unformatted interpolator
					ADVANCED_CUSTOM_FIELD_INTERPOLATORS
				);
				config.event_value = performCalculation(config.event_value).result as string | number | boolean;
				break;
			case 'advanced_editable':
				// we don't need to perform a calculation here. The value is already
				// pulled from the ems properly formatted.
				config.event_value = interpolateValuesIntoString<
					FieldSandboxTemplateContext,
					FieldSandboxTemplateContext
				>(
					config.default_value as string,
					context,
					fetchOptionsForComputedEditableCustomFields(),
					{
						rolesById: hydrationAddons.customFieldData.rolesById,
						isAdminModeEnabled: hydrationAddons.customFieldData.isAdminModeEnabled,
						isBroadway: hydrationAddons.customFieldData.isBroadway,
					},
					// TODO PRSM-9274 keep this using an unformatted interpolator
					ADVANCED_CUSTOM_FIELD_INTERPOLATORS
				);
				break;
			case 'advanced_string_template':
				config.event_value = interpolateValuesIntoString<
					FieldSandboxTemplateContext,
					FieldSandboxTemplateContext
				>(
					config.default_value as string,
					context,
					fetchOptionsForTemplatedStringCustomFields(hydrationAddons.customFieldData.orgCustomFields),
					{
						rolesById: hydrationAddons.customFieldData.rolesById,
						isAdminModeEnabled: hydrationAddons.customFieldData.isAdminModeEnabled,
						isBroadway: hydrationAddons.customFieldData.isBroadway,
					},
					// TODO PRSM-9274 make this use a formatted interpolator rathter than an unformatted one
					ADVANCED_CUSTOM_FIELD_INTERPOLATORS
				);
				break;
		}
		eventCustomFields[customFieldId] = config;
	});
	normalizeEventCustomFieldValuesTimer.stop();
	return eventCustomFields;
}

export function mapHydratationAddonsToEMSCustomFieldsPrismRollup(
	prismEventRollup: PrismEventRollup,
	event: PrismEvent,
	hydrationAddons: EMSHydrationAddons
): EMSCustomField[] {
	const rollupCopy: PrismEventRollup = { ...prismEventRollup };
	// grab the custom fields based on the organization and event custom fields
	// first process and set basic custom fields over the rollup (non advanced ones)
	// for each hydratated custom field map it to an EMSCustomField and set the result
	// to the rollup copy for ExternalReported which is the costCalc used as reference
	rollupCopy.customFields = _.values(
		normalizeEventCustomFieldValuesPrismRollup(rollupCopy, event.custom_fields, hydrationAddons, 'basic')
	).map((customField: AllCustomFieldConfigs, index: number): EMSCustomField => {
		return mapCustomFieldConfigToEMSCustomField(event, CostCalc2.ExternalReported, customField, index);
	});
	// once the basic custom fields are loaded on the ems we can
	// process all advanced custom fields which for getting their actual value
	// we rely on basic custom fields which should already be loaded.
	rollupCopy.customFields = _.values(
		normalizeEventCustomFieldValuesPrismRollup(rollupCopy, event.custom_fields, hydrationAddons)
	).map((customField: AllCustomFieldConfigs, index: number): EMSCustomField => {
		return mapCustomFieldConfigToEMSCustomField(event, CostCalc2.ExternalReported, customField, index);
	});

	return rollupCopy.customFields;
}

function normalizeEventCustomFieldValuesPrismRollup(
	/**
	 * The partial PrismEventRollup (that will not contain any custom fields data)
	 */
	prismEventRollup: PrismEventRollup,
	eventCustomValuesList: CustomFieldEventBackend[],
	hydrationAddons: EMSHydrationAddons,
	customFieldsToProcess: CustomFieldProcessType = 'all'
): CustomFieldsById<AllCustomFieldConfigs> {
	if (!hydrationAddons.customFieldData) {
		throw new Error('hydrationAddons must contain customFieldData!');
	}
	// Build a full list of the organization's custom fields
	const orgCustomFields: CustomFieldsById = scrubCustomFields(
		hydrationAddons.customFieldData.orgCustomFields,
		customFieldsToProcess
	) as CustomFieldsById;
	normalizeEventCustomFieldValuesTimer.start();
	const eventCustomFields: CustomFieldsById<AllCustomFieldConfigs> = scrubCustomFields(
		eventCustomValuesList,
		customFieldsToProcess
	) as Record<number, AllCustomFieldConfigs>;
	const missing: number[] = _.difference(
		Object.keys(orgCustomFields),
		Object.keys(eventCustomFields)
	) as unknown as number[];

	// append any items that were not explicitly set at the event level
	// what happens with event custom fields that are not part of the org custom fields?
	// we now fetch options based on the hydratationAddons but we could be missing options?
	missing.forEach((customFieldId: number): void => {
		const config: AllCustomFieldConfigs = {
			...orgCustomFields[customFieldId],
			event_id: prismEventRollup.id,
			event_value: castCustomValue(undefined, orgCustomFields[customFieldId]) as unknown as string,
		} as AllCustomFieldConfigs;
		const flatType: AllCustomFieldTypes = flattenCustomFieldType(config);
		const contextTemplateData: CustomFieldTemplateData = prismEventRollup;
		const context: FieldSandboxTemplateContext = {
			context: contextTemplateData,
			functionalChipsContext: {
				...contextTemplateData,
				event: prismEventRollup.event,
			},
		};
		switch (flatType) {
			case 'advanced_computed':
			case 'advanced_computed_currency':
				config.event_value = interpolateValuesIntoString<
					FieldSandboxTemplateContext,
					FieldSandboxTemplateContext
				>(
					config.default_value as string,
					context,
					fetchOptionsForComputedCustomFields(hydrationAddons.customFieldData.orgCustomFields),
					{
						rolesById: hydrationAddons.customFieldData.rolesById,
						isAdminModeEnabled: hydrationAddons.customFieldData.isAdminModeEnabled,
						isBroadway: hydrationAddons.customFieldData.isBroadway,
					},
					// TODO PRSM-9274 keep this using an unformatted interpolator
					ADVANCED_CUSTOM_FIELD_INTERPOLATORS
				);
				config.event_value = performCalculation(config.event_value).result as string | number | boolean;
				break;
			case 'advanced_editable':
				// we don't need to perform a calculation here. The value is already
				// pulled from the ems properly formatted.
				config.event_value = interpolateValuesIntoString<
					FieldSandboxTemplateContext,
					FieldSandboxTemplateContext
				>(
					config.default_value as string,
					context,
					fetchOptionsForComputedEditableCustomFields(),
					{
						rolesById: hydrationAddons.customFieldData.rolesById,
						isAdminModeEnabled: hydrationAddons.customFieldData.isAdminModeEnabled,
						isBroadway: hydrationAddons.customFieldData.isBroadway,
					},
					// TODO PRSM-9274 keep this using an unformatted interpolator
					ADVANCED_CUSTOM_FIELD_INTERPOLATORS
				);
				break;
			case 'advanced_string_template':
				config.event_value = interpolateValuesIntoString<
					FieldSandboxTemplateContext,
					FieldSandboxTemplateContext
				>(
					config.default_value as string,
					context,
					fetchOptionsForTemplatedStringCustomFields(hydrationAddons.customFieldData.orgCustomFields),
					{
						rolesById: hydrationAddons.customFieldData.rolesById,
						isAdminModeEnabled: hydrationAddons.customFieldData.isAdminModeEnabled,
						isBroadway: hydrationAddons.customFieldData.isBroadway,
					},
					// TODO PRSM-9274 make this use a formatted interpolator rathter than an unformatted one
					ADVANCED_CUSTOM_FIELD_INTERPOLATORS
				);
				break;
		}
		eventCustomFields[customFieldId] = config;
	});
	normalizeEventCustomFieldValuesTimer.stop();
	return eventCustomFields;
}
