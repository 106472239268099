import { Location } from '@angular/common';
import { Component, HostListener, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Event as AngularRouterEvent, NavigationStart, Router } from '@angular/router';
import {
	JumpToEventComponent,
	SEGMENT_JUMP_TO_EVENT_CLOSE,
	SEGMENT_JUMP_TO_EVENT_OPEN,
} from '@prism-frontend/components/jump-to-event/jump-to-event.component';
import {
	EVENTS_TABLE_URL_PART,
	INVOICES_URL_PART,
} from '@prism-frontend/pages/artist-roster-page/artist-details-paths';
import {
	ARTIST_ROSTER_URL_PART,
	CONTACT_BOOK_BASE_PATH,
} from '@prism-frontend/pages/contact-book-page/contact-book-paths';
import { InsightsPageService } from '@prism-frontend/pages/insights-page/insights-page.service';
import {
	DEAL_TRACKER_URL_PART,
	PAYMENTS_URL_PART,
	PROFIT_AND_LOSS_URL_PART,
	REPORTS_BASE_URL,
	REPORTS_COMMISSIONS_URL,
} from '@prism-frontend/pages/reports-page/reports-page-paths';
import { VENUES_BOOK_URL_PART } from '@prism-frontend/pages/venues-book-page/venues-book-paths';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { OrgSettingsService } from '@prism-frontend/services/legacy/api/org-settings/org-settings.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { UserService } from '@prism-frontend/services/legacy/api/user.service';
import { LoginService } from '@prism-frontend/services/legacy/login.service';
import { AdminModeService } from '@prism-frontend/services/ui/admin-mode.service';
import { ComponentModalizerService, ModalizedDialogRef } from '@prism-frontend/services/ui/component-modalizer.service';
import { EMSFieldService } from '@prism-frontend/services/ui/ems-field.service';
import { PrismTitleService } from '@prism-frontend/services/ui/prism-title.service';
import { ThemeService } from '@prism-frontend/services/ui/theme.service';
import { WINDOW_EVENT_CREATE_NEW_EVENT, WindowService } from '@prism-frontend/services/ui/window.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { OrganizationTypeGateService } from '@prism-frontend/services/utils/organization-type-gate.service';
import { SpinnerFlag, SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import { MatIconEnum } from '@prism-frontend/typedefs/enums/MatIconEnum';
import { WithAutoUnsubscribeComponent } from '@prism-frontend/utils/static/auto-unsubscribe';
import { devDebug } from '@prism-frontend/utils/static/getDebug';
import { getHelpArticleLink } from '@prism-frontend/utils/static/getHelpCenterURL';
import { packageVersionJson } from '@prism-frontend/utils/static/packageVersionJson';
import { platformMetaKeyLabel } from '@prism-frontend/utils/static/platformMetaKeyLabel';
import clipboard from 'clipboard-js';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { SegmentService } from 'ngx-segment-analytics';

const SEGMENT_NAVBAR_ITEM_CLICK: string = 'app-navbar-item-click-';
const SEGMENT_NAVBAR_SHOW_LIST: string = 'app-navbar-show-list';
const SEGMENT_NAVBAR_HIDE_LIST: string = 'app-navbar-hide-list';
const SEGMENT_NAVBAR_USER_MENU_SHOW: string = 'app-navbar-user-menu-show';
const SEGMENT_NAVBAR_LOGO_CLICK: string = 'app-navbar-logo-click';

interface NavbarItem {
	title: string | (() => string);
	link: string;
	matIcon: MatIconEnum;

	// optional parameters
	isVisibleToUser?: () => boolean;
	onClick?: ($event: MouseEvent) => void;
	noOutline?: boolean;
	children?: NavbarItem[];
	hasSubPages?: boolean;
	isDisabled?: () => boolean;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss', '../scss/material.scss'],
	providers: [ApiService],
})
export class AppComponent extends WithAutoUnsubscribeComponent implements OnInit {
	public APP_VERSION: string = packageVersionJson.version;
	public listenFunction: Function;
	public showSpinner: SpinnerFlag;
	public template: String = 'Full';
	public isGrayBackgroundActivated: boolean = false;
	public isLoggedIn: boolean = false;
	public knowledgeBaseURL: string = getHelpArticleLink('knowledgeBase');

	private _isAppbarVisible: boolean = true;

	public navbarItems: NavbarItem[] = [];
	public settingsNavbarItem: NavbarItem = {
		link: '/settings',
		title: 'Settings',
		matIcon: MatIconEnum.settings,
	};
	public showMenuItems: boolean = false;

	@ViewChildren(MatMenuTrigger)
	private matMenus: QueryList<MatMenuTrigger>;

	private jumpToEventComponent: ModalizedDialogRef<JumpToEventComponent, void>;

	private static navbarItemToSegmentEvent(item: NavbarItem): string {
		return `${SEGMENT_NAVBAR_ITEM_CLICK}${item.link}`;
	}

	public constructor(
		private componentModalizerService: ComponentModalizerService,
		private location: Location,
		private organizationTypeService: OrganizationTypeGateService,
		private windowService: WindowService,
		private prismTitleService: PrismTitleService,
		private activatedRoute: ActivatedRoute,
		private insightsPageService: InsightsPageService,
		public adminModeService: AdminModeService,
		public apiService: ApiService,
		public emsFieldService: EMSFieldService,
		public featureGateService: FeatureGateService,
		public loginService: LoginService,
		public orgSettingsService: OrgSettingsService,
		public permissionsService: PermissionsService,
		public renderer: Renderer2,
		public router: Router,
		public segment: SegmentService,
		public spinner: SpinnerService,
		public themeService: ThemeService,
		public userService: UserService
	) {
		super();
		this.showSpinner = new SpinnerFlag();
		spinner.setAppRef(this.showSpinner);
		if (environment.name === 'local' && !localStorage.getItem('debug')) {
			localStorage.setItem('debug', 'prism:dev*,prism:error*');
		}
		devDebug(
			'logging meessages to the console. Run `localStorage.debug = "prism*";` in the console to see ALL debug messages, or scope it down with `localStorage.debug = "prism*,-prism:verbose*,-prism:error*"`'
		);
	}

	public get userDetails(): string {
		return `Name: ${this.userService.user.name}\nEmail: ${this.userService.user.email}`;
	}

	public isIpadPortraitOrSmaller(): boolean {
		return WindowService.isIpadPortraitOrSmaller();
	}

	public isAppbarVisible(): boolean {
		return this.isLoggedIn && this._isAppbarVisible;
	}

	public get isLoggedInAsUser(): boolean {
		return this.loginService.isLoggedInAsUser;
	}

	public get isProduction(): boolean {
		return environment.production;
	}

	public isLinkActive(navItem: NavbarItem): boolean {
		// inspired by https://github.com/angular/angular/issues/13205#issuecomment-388880401
		const url: string = navItem.link;
		const exact: boolean = !navItem.hasSubPages;
		const queryIdx: number = this.location.path().indexOf('?');
		let baseUrl: string = queryIdx === -1 ? this.location.path() : this.location.path().slice(0, queryIdx);

		if (!baseUrl) {
			baseUrl = '/';
		}

		if (exact) {
			return baseUrl === url;
		}
		return baseUrl.startsWith(url);
	}

	public logout(evt: UIEvent, doConfirm: boolean = true): void {
		evt.stopPropagation();
		evt.preventDefault();
		if (doConfirm) {
			const confirmLogout: boolean = confirm('Are you sure you want to log out?');
			if (!confirmLogout) {
				return;
			}
		}
		// logging out as user refreshes
		if (this.loginService.isLoggedInAsUser) {
			this.loginService.logOutAsUser();
			return;
		}

		this.loginService.logOut();
	}

	public ngOnInit(): void {
		this.loginService.validateLogin();
		this.loginService.isLoggedInStream$.subscribe((status: boolean): void => {
			if (!status) {
				this.isLoggedIn = false;
				return;
			}
			// If we already saw a logged in status, gtfo
			if (this.isLoggedIn) {
				return;
			}
			// Should only get here once, the first time the user
			//  shows up as logged in
			this.isLoggedIn = true;
			this.loginService.loadUserData();
		});

		/**
		 * Until unified page styles are implemented `isGrayBackgroundActivated` switches between old and new bg style for every page
		 * https://bitbucket.org/onesolstice/prism-angular/pull-requests/207/prsm-1638-update-venue-permissions-page/diff#comment-84056491
		 */
		this.addSubscription(
			this.router.events.subscribe((e: AngularRouterEvent): void => {
				if (!(e instanceof NavigationStart)) {
					return;
				}
				const evt: NavigationStart = e;
				const regex: RegExp = /^\/permissions\/(event|venue)\/\d+/;
				this.isGrayBackgroundActivated = regex.test(evt.url);
				if (!this.isGrayBackgroundActivated) {
					this.isGrayBackgroundActivated = evt.url.split('?')[0] === '/';
				}

				// Scroll to the top of the main window when navigating to restore scroll position
				window.scrollTo(0, 0);

				// close menues when we navigate
				this.showMenuItems = false;
				this.closeMenu();

				// this unlocks scroll on any navigation, just in case it gets locked by a sub-component
				// that has not yet unlocked it
				this.windowService.clearAllScrollLocks();
			})
		);

		this.setNavBarItems();
		this.oneAndDone(this.orgSettingsService.loaded$).then((): void => {
			this.setNavBarItems();
		});

		this.prismTitleService.updateTitleOnInit(this.activatedRoute.snapshot);
	}

	public get appThemeCSSClass(): string {
		return 'prism-theme-light';
		// return 'prism-theme-dark';
	}

	public get appRouteToCSSClass(): string {
		// split the current route on `/`, filtering out any ID-based
		// fields
		// this makes it easy to add page-specific styles to the top level panel
		return (
			'route-' +
			window.location.pathname
				.split('/')
				.filter((pathPart: string): boolean => {
					// filter out any path portion that is all digits
					return pathPart && !/\d+/.test(pathPart);
				})
				.join('-')
		);
	}

	public handleMenuOpenClick(evt: UIEvent): void {
		evt.stopPropagation();
		evt.preventDefault();
	}

	public closeMenu(): void {
		this.matMenus.forEach((menu: MatMenuTrigger): void => {
			menu.closeMenu();
		});
	}

	public menuItemClick($event: MouseEvent, item: NavbarItem): boolean | void {
		this.segment.track(AppComponent.navbarItemToSegmentEvent(item));

		$event.stopPropagation();
		if ($event.ctrlKey || $event.metaKey) {
			return true;
		}
		this.closeMenu();

		if (item.onClick) {
			item.onClick($event);
		}
	}

	public userMenuClick(): boolean {
		this.segment.track(SEGMENT_NAVBAR_USER_MENU_SHOW);
		return true;
	}

	public userMenuLogoClick(): boolean {
		this.segment.track(SEGMENT_NAVBAR_LOGO_CLICK);
		return true;
	}

	public handleAddShowClicked(): void {
		$(window).trigger(WINDOW_EVENT_CREATE_NEW_EVENT);
	}

	public navbarItemClassName(item: NavbarItem): string {
		return `navbar-item-${this.resolveNavTitle(item).toLowerCase().replace(/\s+/g, '-')}`;
	}

	public toggleNavMenuItems(): void {
		this.segment.track(this.showMenuItems ? SEGMENT_NAVBAR_HIDE_LIST : SEGMENT_NAVBAR_SHOW_LIST);

		this.showMenuItems = !this.showMenuItems;

		if (this.showMenuItems) {
			this.windowService.lockScrollLegacy();
		} else {
			this.windowService.unlockScrollLegacy();
		}
	}

	private setNavBarItems(): void {
		const paymentTrackingOrg: boolean = this.featureGateService.orgHasFeature('PAYMENT_TRACKING');
		const dealTrackingOrg: boolean = this.featureGateService.orgHasFeature('DEAL_TRACKER');
		const agencyOrg: boolean = this.organizationTypeService.isAgent;

		const reportsNavItem: NavbarItem = {
			title: 'Reports',
			link: `${REPORTS_BASE_URL}/${PROFIT_AND_LOSS_URL_PART}`,
			matIcon: MatIconEnum.insert_chart,
			hasSubPages: true,
			isVisibleToUser: (): boolean => {
				return this.permissionsService.userCan('view-reporting');
			},
			children: [],
		};
		if (paymentTrackingOrg || dealTrackingOrg) {
			reportsNavItem.link = `${REPORTS_BASE_URL}`;
			reportsNavItem.children = [
				{
					title: 'Profit & Loss',
					link: `${REPORTS_BASE_URL}/${PROFIT_AND_LOSS_URL_PART}`,
					matIcon: MatIconEnum.insert_chart,
					isVisibleToUser: (): boolean => {
						return this.permissionsService.userCan('view-reporting');
					},
				},
				{
					title: 'Payments',
					link: `${REPORTS_BASE_URL}/${PAYMENTS_URL_PART}`,
					matIcon: MatIconEnum.attach_money,
					isVisibleToUser: (): boolean => {
						return this.permissionsService.userCan('view-reporting') && paymentTrackingOrg;
					},
				},
				{
					title: 'Deal Tracker',
					link: `${REPORTS_BASE_URL}/${DEAL_TRACKER_URL_PART}`,
					matIcon: MatIconEnum.handshake,
					isVisibleToUser: (): boolean => {
						return this.permissionsService.userCan('view-reporting') && dealTrackingOrg;
					},
				},
				{
					title: 'Invoices/Receipts',
					link: `${REPORTS_BASE_URL}/${INVOICES_URL_PART}`,
					matIcon: MatIconEnum.receipt,
					isVisibleToUser: (): boolean => {
						return this.permissionsService.userCan('view-reporting') && agencyOrg;
					},
				},
				{
					title: 'Commissions',
					link: `${REPORTS_COMMISSIONS_URL}`,
					matIcon: MatIconEnum.percent,
					isVisibleToUser: (): boolean => {
						return this.permissionsService.userCan('view-reporting') && agencyOrg;
					},
				},
				{
					title: 'Events',
					link: `${REPORTS_BASE_URL}/${EVENTS_TABLE_URL_PART}`,
					matIcon: MatIconEnum.calendar_month,
					isVisibleToUser: (): boolean => {
						return this.permissionsService.userCan('view-reporting');
					},
				},
			];
		}
		const contactsNavItem: NavbarItem = {
			title: 'Contacts',
			link: `/${CONTACT_BOOK_BASE_PATH}`,
			matIcon: MatIconEnum.contacts,
			isVisibleToUser: (): boolean => {
				return (
					this.permissionsService.isAdmin ||
					this.permissionsService.userCan('view-contactbook') ||
					this.permissionsService.userCan('edit-contactbook')
				);
			},
			hasSubPages: true,
		};
		let venuesBookNavItem: NavbarItem;
		let artistRosterNavItem: NavbarItem;
		if (this.organizationTypeService.isAgent) {
			artistRosterNavItem = {
				title: 'Artist Roster',
				link: `/${ARTIST_ROSTER_URL_PART}`,
				matIcon: MatIconEnum.mic_external_on,
				isVisibleToUser: (): boolean => {
					return (
						this.permissionsService.isAdmin ||
						this.permissionsService.userCan('view-roster') ||
						this.permissionsService.userCan('edit-roster')
					);
				},
				hasSubPages: true,
			};
			venuesBookNavItem = {
				title: 'Venues & Stages',
				link: `/${VENUES_BOOK_URL_PART}`,
				matIcon: MatIconEnum.location_city,
			};
		}

		const insightsNavBarItem: NavbarItem = {
			title: (): string => {
				const isInsightsDisabled: boolean =
					this.insightsPageService.canUsersSeePreInsights() && !this.insightsPageService.canUserSeeInsights();
				return `Prism Insights${isInsightsDisabled ? ' (Coming Soon)' : ''}`;
			},
			link: '/insights',
			hasSubPages: true,
			matIcon: MatIconEnum.tips_and_updates,
			isDisabled: (): boolean => {
				return (
					this.insightsPageService.canUsersSeePreInsights() && !this.insightsPageService.canUserSeeInsights()
				);
			},
			isVisibleToUser: (): boolean => {
				return (
					this.insightsPageService.canUsersSeePreInsights() || this.insightsPageService.canUserSeeInsights()
				);
			},
		};

		// the links that appear in the navbar
		this.navbarItems = [
			{
				title: 'Add Show',
				link: '/calendar/new',
				matIcon: 'add',
				isVisibleToUser: (): boolean => {
					return this.permissionsService.userCanCreateEvents();
				},
				onClick: (): void => {
					return this.handleAddShowClicked();
				},
			},
			{
				title: 'Calendar',
				link: '/calendar',
				matIcon: 'date_range',
			},
			{
				title: 'Event List',
				link: '/',
				matIcon: 'list',
				noOutline: true,
			},
			reportsNavItem,
			{
				title: 'Tasks',
				link: '/tasks',
				matIcon: 'check_box',
			},
			contactsNavItem,
			venuesBookNavItem,
			artistRosterNavItem,
			{
				title: 'Tours',
				link: '/tours',
				matIcon: 'directions_bus',
				isVisibleToUser: (): boolean => {
					if (this.adminModeService.adminMode) {
						return true;
					}

					if (!this.featureGateService.orgHasFeature('TOUR_INDEX')) {
						return false;
					}

					return (
						this.featureGateService.orgHasFeature('TOURING') &&
						this.permissionsService.userCan('view-tour-index')
					);
				},
			},
			insightsNavBarItem,
			{
				title: 'Style Guide',
				link: '/styleguide',
				matIcon: 'devices_other',
				isVisibleToUser: (): boolean => {
					return this.adminModeService.adminMode && this.featureGateService.isFeatureEnabled('STYLE_GUIDE');
				},
			},
			{
				title: 'Portal',
				link: '/portal',
				matIcon: 'security',
				isVisibleToUser: (): boolean => {
					return this.adminModeService.adminMode && this.permissionsService.isPrismAdmin;
				},
			},
		]
			.filter((i: NavbarItem): boolean => {
				return !!i;
			})
			.map((navBarItem: NavbarItem): NavbarItem => {
				return _.defaults(navBarItem, {
					isVisibleToUser: (): boolean => {
						return true;
					},
				});
			});
	}

	// https://stackoverflow.com/a/63770342/239242
	@HostListener('window:keydown', ['$event'])
	public onGlobalKeydownListener(event: KeyboardEvent): void {
		// cmd + k ==> Jump to Event modal dialog
		if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
			this.openJumpToEvent('keyboard-shortcut');
			event.preventDefault();
		}
	}

	public openJumpToEvent(invocationMethod: 'keyboard-shortcut' | 'app-sidebar'): void {
		if (this.jumpToEventComponent) {
			// triggering the shortcut with the dialog already open dismisses it
			this.jumpToEventComponent.close();
			// no need to set to undefined here because the afterClosed below
			// still executes
			return;
		}

		this.segment.track(SEGMENT_JUMP_TO_EVENT_OPEN, {
			invocationMethod,
		});
		// JumpToEventComponent handles dismissing itself upon page navigation
		this.jumpToEventComponent = this.componentModalizerService.openDialog<JumpToEventComponent, void>(
			JumpToEventComponent,
			{},
			{},
			{
				fullScreenOnMobile: true,
				includeCloseButton: true,
				matDialogOptions: {
					disableClose: false,
					width: '750px',
				},
			}
		);

		this.jumpToEventComponent
			.afterClosed()
			.toPromise()
			.then((): void => {
				this.segment.track(SEGMENT_JUMP_TO_EVENT_CLOSE);
				this.jumpToEventComponent = undefined;
			});
	}

	// expose for the template
	public get jumpToEventTooltip(): string {
		return `Jump to Event... (${platformMetaKeyLabel()} + k)`;
	}

	public copyOrganizationId(): void {
		if (this.isLoggedInAsUser) {
			clipboard.copy(`${this.userService.user.organization_id}`);
		}
	}

	protected resolveNavTitle(navItem: NavbarItem): string {
		if (_.isFunction(navItem.title)) {
			return navItem.title();
		}
		return navItem.title;
	}

	protected isNavItemDisabled(navItem: NavbarItem): boolean {
		return navItem.isDisabled && navItem.isDisabled();
	}
}
