import { AllCostCalc2Set } from '@prism-frontend/typedefs/AllCostCalc2';
import { CostCalcDependentValue } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSWarning } from '@prism-frontend/typedefs/ems/ems-warnings';
import { EMSFieldDefs } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { fetchPrismEventRollupArrayDebug } from '@prism-frontend/typedefs/ems/fetchPrismEventRollupArrayDebug';
import { EMSWarningType } from '@prism-frontend/typedefs/enums/ems-warnings';
import { starify } from '@prism-frontend/utils/static/test-helpers/listAllPropsOnObject';
import _ from 'lodash';

function areSetsEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
	// First, check if the sizes are different
	if (set1.size !== set2.size) {
		return false;
	}

	// If sizes are the same, check if every element in set1 is also in set2
	for (const item of set1) {
		if (!set2.has(item)) {
			return false;
		}
	}

	// If we've made it this far, the sets are equal
	return true;
}

export function stripEMSMetaFields<T extends Object>(value: T): T {
	return _.omit(value, 'emsPath', 'emsMetadataId') as T;
}

export function isCostCalcDependentValue(value: unknown): value is CostCalcDependentValue<unknown> {
	if (!_.isObject(value)) {
		return false;
	}
	const keys: Set<string> = new Set(Object.keys(stripEMSMetaFields(value)));
	return areSetsEqual(keys, AllCostCalc2Set);
}

/**
 * Options for controlling the behavior of the `walkPrismEventRollup` function.
 */
interface WalkPrismEventRollupOptions {
	/**
	 * Function to handle `CostCalcDependentValue` items found during the walk.
	 * @param value - The `CostCalcDependentValue` instance to be handled.
	 * @param emsPath - The current EMS path corresponding to the value.
	 * @returns The transformed value.
	 */
	handleCostCalcDependentValue?: (value: CostCalcDependentValue<unknown>, emsPath: keyof EMSFieldDefs) => unknown;

	/**
	 * Optional function to add warnings encountered during the walk.
	 * @param warning - The `EMSWarning` to be added.
	 */
	addWarning?: (warning: EMSWarning) => void;

	/**
	 * Optional function to determine whether to include an array element during the walk.
	 * @param value - The array element to be evaluated.
	 * @returns A boolean indicating whether to include the element.
	 */
	shouldIncludeArrayElement?: (value: unknown) => boolean;

	/**
	 * mutate an object on the array
	 * note that this is called AFTER shouldIncludeArrayElement
	 * @param value
	 * @returns mutated value
	 */
	mapElement?: (value: object, emsPath: keyof EMSFieldDefs) => object;
}

/**
 * Recursively walks through an object, processing `CostCalcDependentValue`
 * instances and allowing custom handling of array elements and warnings.
 *
 * This function is useful in scenarios where you need to traverse complex
 * nested structures, especially when dealing with EMS data structures that
 * may contain cost calculation dependent values.
 *
 * Use cases include:
 * - Extracting specific cost calculation values from a nested data structure.
 * 	 (proxy-ems-rollup uses it in order to convert the PrismEventRollup to an EMS)
 * - Flattening or transforming `CostCalcDependentValue` instances.
 *   (fetchPrismEventRollup uses it for this when automatically flattening like values)
 * - Filtering out array elements based on custom logic.
 *   (proxy-ems-rollup also uses it for this in order to remove array elements
 *   that should not be present for a given CostCalc2)
 *
 * @template T - The type of the object being walked.
 * @param obj - The object to be walked through.
 * @param options - Options to control the behavior of the walk.
 * @param curEMSPath - (Internal) The current path in the `EMSFieldDefs`.
 *   Users should not pass this parameter.
 * @returns The transformed object.
 */
export function walkPrismEventRollup<T, U = T>(
	obj: T,
	options: WalkPrismEventRollupOptions,
	/**
	 * Internal parameter to track the current EMS path during recursion.
	 * Users of this function should not pass this parameter.
	 */
	curEMSPath: keyof EMSFieldDefs = '' as keyof EMSFieldDefs
): U {
	// Define default options if they are not provided
	const defaultOptions: Partial<WalkPrismEventRollupOptions> = {
		// Default addWarning to a no-operation function if not provided
		addWarning: _.noop,
		// Default shouldIncludeArrayElement to always return true if not provided
		shouldIncludeArrayElement: _.constant(true),
		handleCostCalcDependentValue: _.identity,
		mapElement: _.identity,
	};
	// Merge the provided options with the default options
	const { handleCostCalcDependentValue, addWarning, shouldIncludeArrayElement, mapElement } = _.defaults(
		{},
		options,
		defaultOptions
	);

	// If the object is an array, process each element
	if (_.isArray(obj)) {
		return _.reduce(
			obj,
			(acc: unknown[], element: T, index: number): unknown[] => {
				// Construct the new EMS path for the current array element
				const newPath: keyof EMSFieldDefs = `${curEMSPath}${
					curEMSPath ? '.' : ''
				}${index}` as keyof EMSFieldDefs;

				// If the element is not a plain object, add it to the accumulator as is
				if (!_.isPlainObject(element)) {
					acc.push(element);
					return acc;
				}

				// If the element lacks an 'id' property, log a warning
				if (!element['id' as keyof T]) {
					fetchPrismEventRollupArrayDebug(
						`encountered an array element that lacks an ID, processing: ${newPath}`,
						element
					);
					// Use the addWarning function to record the warning
					addWarning({
						type: EMSWarningType.ArrayObjectDoesNotHaveID,
						description: `encountered an array element that lacks an ID, processing: ${starify(
							newPath as string
						)}`,
					});
				}

				// Determine whether to include this array element based on shouldIncludeArrayElement function
				if (!shouldIncludeArrayElement(element)) {
					// If not including the element, skip adding it to the accumulator
					return acc;
				}

				// Recursively process the element and add it to the accumulator
				acc.push(
					walkPrismEventRollup(
						mapElement(element as object, newPath),
						{
							handleCostCalcDependentValue,
							addWarning,
							shouldIncludeArrayElement,
							mapElement,
						},
						newPath
					)
				);
				return acc;
			},
			[] // Initialize the accumulator as an empty array
		) as U;
	} else if (isCostCalcDependentValue(obj)) {
		// If the object itself is a CostCalcDependentValue, handle it
		return handleCostCalcDependentValue(obj, curEMSPath) as U;
	} else if (_.isPlainObject(obj)) {
		const mappedElement: object = mapElement(obj as object, curEMSPath);
		// If the object is a plain object, process each key-value pair
		return _.mapValues(mappedElement, (value: unknown, key: string): unknown => {
			// Construct the new EMS path for the current property
			const newPath: keyof EMSFieldDefs = `${curEMSPath}${curEMSPath ? '.' : ''}${key}` as keyof EMSFieldDefs;

			// If the value is a CostCalcDependentValue, handle it using the provided function
			if (isCostCalcDependentValue(value)) {
				return handleCostCalcDependentValue(value, newPath);
			} else if (_.isArray(value) || _.isPlainObject(value)) {
				if (_.isPlainObject(value)) {
					value = mapElement(value as object, newPath);
				}

				// If the value is an array or object, recursively process it
				return walkPrismEventRollup(
					value,
					{
						handleCostCalcDependentValue,
						addWarning,
						shouldIncludeArrayElement,
						mapElement,
					},
					newPath
				);
			}
			// For other types of values, return them as is
			return value;
		}) as U;
	} else {
		// For primitive types or other values, return the object as is
		return obj as unknown as U;
	}
}
