import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'prism-badge',
	template: `{{ text }}<ng-content></ng-content>`,
	styleUrls: ['./prism-badge.component.scss'],
})
export class PrismBadgeComponent {
	@Input() public text: string = '';

	/**
	 * includeStyles - if false, no css will apply to the rendered component
	 * it will be completely left up to you as the consumer to style it.
	 */
	@Input()
	@HostBinding('class.prism-badge')
	public includeStyles: boolean = true;
}
