export enum EMSFieldType {
	AdditionalRevenueArray = 'EMSAdditionalRevenue[]',
	AfterType = 'AfterType',
	BonusTypes = 'BonusTypes',
	CollectedBy = 'CollectedBy',
	boolean = 'boolean',
	CostGroupCategory = 'CostGroupCategory',
	CostGroupType = 'CostGroupType',
	CustomFieldType = 'CustomFieldType',
	EMSCoProSummary = 'EMSCoProSummary',
	EMSCustomFieldValue = 'EMSCustomFieldValue',
	DealTypes = 'DealTypes',
	DealStatus = 'DealStatus',
	date = 'date',
	dollarAmount = 'dollarAmount',
	EMSAgentsArray = 'EMSAgentPayout[]',
	EMSAdjustmentsArray = 'EMSAdjustment[]',
	EMSCoProTicketSummaryArray = 'EMSCoProRevenueSummary[]',
	EMSCoProTicketSplitArray = 'EMSCoProRevenueSplit[]',
	EMSCoProExpenseSplitArray = 'EMSCoProExpenseSplit[]',
	EMSCoProV2SummaryItem = 'EMSCoProV2SummaryItem',
	EMSCoProV2SummaryItemArray = 'EMSCoProV2SummaryItem[]',
	EMSCoProV2RevenueSummary = 'EMSCoProV2RevenueSummary',
	EMSCoProV2ExpensesSummary = 'EMSCoProV2ExpensesSummary',
	EMSBonusArray = 'EMSBonus[]',
	EMSCustomFieldArray = 'EMSCustomField[]',
	EMSEventFeeArray = 'EMSEventFee[]',
	EMSFixedCostArray = 'EMSFixedCost[]',
	EMSRentalRoomCostArray = 'EMSRentalRoomCost[]',
	EMSRentalRoomCosts = 'EMSRentalRoomCosts',
	EMSPartnerDealArray = 'EMSPartnerDeal[]',
	EMSPaymentsArray = 'EMSPayments[]',
	EMSPayoutArray = 'EMSPayout[]',
	EMSTalentPayoutArray = 'EMSTalentPayout[]',
	EMSAgentPayoutTotalsTotalsArray = 'EMSAgentPayoutTotals[]',
	PrismEventsArray = 'PrismEvent[]',
	EMSWarningArray = 'EMSWarning[]',
	SMSTicketTiersAverage = 'SMSTicketTiersAverage[]',
	EMSRetroactiveBonusArray = 'EMSRetroactiveBonus[]',
	EMSTicketAggregate = 'EMSTicketAggregate',
	EMSTicketArray = 'EMSTicketArrayPayout[]',
	EMSTicketTotal = 'EMSTicketTotal',
	EMSTicketCommissionTotal = 'EMSTicketCommissionTotal',
	EMSVariableCostArray = 'EMSVariableCost[]',
	EventStatus = 'EventStatus',
	EventFeeType = 'EventFeeType',
	MathSummaryType = 'MathSummaryType',
	number = 'number',
	PaymentStatus = 'PaymentStatus',
	PaymentType = 'PaymentType',
	percentage = 'percentage',
	RentalData = 'RentalData',
	ThresholdType = 'ThresholdType',
	string = 'string',
	stringArray = 'string[]',
	VariableCostType = 'VariableCostType',
	id = 'id',
}
