import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';

/**
 * Generally, you should use fetchLegacyEMSParamsFromCostCalc2 instead of this map,
 * but this map could be more performant in that it doesn't require a function call.
 */
export const CostCalc2ToLegacyEMSParamsMap: Record<CostCalc2, [CostCalc, boolean]> = {
	[CostCalc2.ExternalBudgeted]: [CostCalc.Budgeted, true],
	[CostCalc2.ExternalEstimated]: [CostCalc.Estimated, true],
	[CostCalc2.ExternalReported]: [CostCalc.Reported, true],
	[CostCalc2.InternalActual]: [CostCalc.Actual, false],
	[CostCalc2.InternalPotential]: [CostCalc.Potential, false],
	[CostCalc2.InternalEstimated]: [CostCalc.Estimated, false],
};

export function fetchLegacyEMSParamsFromCostCalc2(costCalc2: CostCalc2): [CostCalc, boolean] {
	if (!(costCalc2 in CostCalc2ToLegacyEMSParamsMap)) {
		throw new Error(`Invalid CostCalc2 value: ${costCalc2}`);
	}
	return CostCalc2ToLegacyEMSParamsMap[costCalc2];
}
