import { AgentUser } from '@prism-frontend/typedefs/AgentUser';
import { Contact } from '@prism-frontend/typedefs/contact';
import { EventFileAttachment } from '@prism-frontend/typedefs/file-attachment';
import { resolveTalentImageUrl } from '@prism-frontend/typedefs/resolveTalentImageUrl';
import { Talent, TalentImage } from '@prism-frontend/typedefs/talent';
import { Type, plainToClass } from 'class-transformer';
import { IsArray, IsBoolean, IsInt, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

export type ArtistMap = Record<number, Artist>;

export class Artist {
	public constructor(artist?: Partial<Artist>) {
		return plainToClass(Artist, artist);
	}

	@IsInt()
	public id: number;

	@IsInt()
	public organization_id: number;

	@IsBoolean()
	public is_archived: boolean;

	@Type((): typeof Talent => {
		return Talent;
	})
	@ValidateNested()
	public talent: Talent;

	@IsNumber()
	public talent_id: number;

	@Type((): typeof Contact => {
		return Contact;
	})
	@ValidateNested()
	@IsOptional()
	public manager?: Contact;

	@IsNumber()
	public manager_contact_id: number;

	@IsNumber()
	public contract_signer_id: number | null = null;

	@Type((): typeof Contact => {
		return Contact;
	})
	@ValidateNested()
	@IsOptional()
	public contract_signer?: Contact;

	@Type((): typeof AgentUser => {
		return AgentUser;
	})
	@ValidateNested()
	public agents: AgentUser[] = [];

	@IsString()
	public notes: string = '';

	@IsOptional()
	@IsString()
	public updated_at: string | null;

	@IsOptional()
	@IsString()
	public created_at: string | null;

	@IsArray()
	@ValidateNested()
	@Type((): typeof EventFileAttachment => {
		return EventFileAttachment;
	})
	public files: EventFileAttachment[] = [];

	public get artistImage(): string {
		return resolveTalentImageUrl(this.talent?.images);
	}

	@IsArray()
	@Type((): typeof Contact => {
		return Contact;
	})
	@ValidateNested()
	public contacts: Contact[] = [];

	public get artistName(): string {
		return this.talent?.name || 'TBD';
	}

	public get artistSignerName(): string {
		if (!this.contract_signer) {
			return '';
		}
		return this.contract_signer.company;
	}

	public set manager_contact_ids(ids: number[]) {
		if (ids.length > 1) {
			throw new Error('Ther can be only one manager contact');
		}
		this.manager_contact_id = ids[0];
	}

	public get manager_contact_ids(): number[] {
		return [this.manager_contact_id];
	}

	public set contract_signer_ids(ids: number[]) {
		if (ids.length > 1) {
			throw new Error('Ther can be only one contract signer');
		}
		this.contract_signer_id = ids[0];
	}

	public get contract_signer_ids(): number[] {
		return [this.contract_signer_id];
	}

	public set talentId(id: number) {
		if (this.talent) {
			this.talent.id = id;
		}
	}

	public get talentId(): number {
		return this.talent?.id || null;
	}

	public set talentName(talentName: string) {
		if (this.talent) {
			this.talent.name = talentName;
		}
	}

	public get talentName(): string {
		return this.talent?.name || '';
	}

	public set talentSpotifyId(spotify_id: string) {
		if (this.talent) {
			this.talent.spotify_id = spotify_id;
		}
	}

	public get talentSpotifyId(): string {
		return this.talent?.spotify_id || '';
	}

	public set talentImages(images: TalentImage[]) {
		if (this.talent) {
			this.talent.images = images;
		}
	}

	public get talentImages(): TalentImage[] {
		return this.talent?.images || [];
	}
}
