import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import { ADMIN_OBJ } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/ADMIN_OBJ';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import {
	CostCalcDependentValue,
	EMSAdditionalRevenue,
	EMSRollup,
	PrismEventRollup,
} from '@prism-frontend/typedefs/ems/ems-typedefs';
import { isCostCalcDependentValue } from '@prism-frontend/typedefs/ems/walkPrismEventRollup';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { MathSummaryType } from '@prism-frontend/typedefs/enums/MathSummaryType';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';

interface FindAdditionalRevenueByNameArg {
	name: string;
	costCalc: CostCalc2;
	property: keyof EMSAdditionalRevenue<number, string, boolean> | typeof ADMIN_OBJ;
}

export const findAdditionalRevenueByName: FunctionalChip<FindAdditionalRevenueByNameArg> = {
	identifier: 'findAdditionalRevenueByName',
	name: 'Find Additional Revenue Item',
	fallbackValue: (): string => {
		return '0';
	},
	theFunction(
		argument: FindAdditionalRevenueByNameArg,
		_orgData: FunctionalChipOrgData,
		data: FunctionalChipAdditionalData
	): FunctionalChipFunctionResult {
		if (data.type !== MathSummaryType.PrismEventRollup && data.type !== MathSummaryType.PrismEventRollupFlat) {
			return getEMSRollupAdditionalRevenueByNameArg(argument, data as EMSRollup);
		}
		return getPrismEventRollupAdditionalRevenueByNameArg(argument, data as PrismEventRollup);
	},
	formFields(
		data: FunctionalChipOrgData,
		value: FindAdditionalRevenueByNameArg
	): AllSimpleFormFieldConfigs<FindAdditionalRevenueByNameArg>[] {
		const suggestions: (keyof EMSAdditionalRevenue<number, string, boolean> | typeof ADMIN_OBJ)[] = [
			// 'name',
			'amount',
			// 'includeInCoProSplit',
			// 'postfix',
		];
		if (data.isAdminModeEnabled) {
			suggestions.push(ADMIN_OBJ);
		}
		let costCalcField: AllSimpleFormFieldConfigs<FindAdditionalRevenueByNameArg>;
		if (value.property === 'amount' || value.property === ADMIN_OBJ) {
			costCalcField = {
				label: 'Calculation',
				description: '',
				fieldType: 'select',
				// TODO PRSM-XXXX map cost calc to human readable
				suggestions: [CostCalc2.InternalEstimated, CostCalc2.InternalActual],
				key: 'costCalc',
				required: true,
				editable: true,
			};
		}
		return [
			{
				label: 'Name',
				description: 'Enter a name for the Additional Revenue you wish to find',
				fieldType: 'text',
				key: 'name',
				required: true,
				editable: true,
			},
			{
				label: 'Property',
				description: '',
				fieldType: 'select',
				// TODO PRSM-XXXX map to human readable
				// TODO PRSM-XXXX associate with ems metadata for field
				suggestions,
				key: 'property',
				required: true,
				editable: true,
			},
			costCalcField,
		];
	},
	chipText: (value: FindAdditionalRevenueByNameArg): string => {
		// TODO PRSM-XXXX map cost calc to human readable
		return `Find Additional Revenue: "${value.name}" - ${value.costCalc || CostCalc2.InternalEstimated} - ${
			value.property
		}`;
	},
};

function getEMSRollupAdditionalRevenueByNameArg(
	argument: FindAdditionalRevenueByNameArg,
	emsRollup: EMSRollup
): FunctionalChipFunctionResult {
	const additionalRevenue: EMSAdditionalRevenue<number, string, boolean>[] =
		emsRollup[argument.costCalc || CostCalc2.InternalEstimated].additionalRevenue;
	const searcher: FuzzySearch<EMSAdditionalRevenue<number, string, boolean>> = new FuzzySearch(
		additionalRevenue,
		['name'],
		{
			caseSensitive: false,
			sort: true,
		}
	);
	// very forgiving fuzzy search, should users be able to opt into this?
	const result: EMSAdditionalRevenue<number, string, boolean>[] = searcher.search(argument.name);
	if (argument.property === ADMIN_OBJ) {
		return JSON.stringify(_.first(result)) as never;
	}
	if (!result.length) {
		return null;
	}
	return (_.first(result)[argument.property] || 0) as FunctionalChipFunctionResult;
}

function getPrismEventRollupAdditionalRevenueByNameArg(
	argument: FindAdditionalRevenueByNameArg,
	prismEventRollup: PrismEventRollup
): FunctionalChipFunctionResult {
	const additionalRevenue: EMSAdditionalRevenue<
		CostCalcDependentValue<number>,
		CostCalcDependentValue<string>,
		CostCalcDependentValue<boolean>
	>[] = prismEventRollup.additionalRevenue;
	const searcher: FuzzySearch<
		EMSAdditionalRevenue<
			CostCalcDependentValue<number>,
			CostCalcDependentValue<string>,
			CostCalcDependentValue<boolean>
		>
	> = new FuzzySearch(additionalRevenue, ['name'], {
		caseSensitive: false,
		sort: true,
	});
	// very forgiving fuzzy search, should users be able to opt into this?
	const result: EMSAdditionalRevenue<
		CostCalcDependentValue<number>,
		CostCalcDependentValue<string>,
		CostCalcDependentValue<boolean>
	>[] = searcher.search(argument.name);
	if (argument.property === ADMIN_OBJ) {
		return JSON.stringify(_.first(result)) as never;
	}
	if (!result.length) {
		return null;
	}
	const value: unknown = _.first(result)[argument.property];
	// when fetching a property for a particular additional revenue item we find
	// we need to verify if it is a cost calc dependent value or not, if it is
	// we need to return the value for the cost calc that was passed in on the argument
	// otherwise we can just return the value. By default we use InternalEstimated
	// if a cost calc is not defined on the custom field data
	if (isCostCalcDependentValue(value)) {
		return (value[argument.costCalc || CostCalc2.InternalEstimated] || 0) as FunctionalChipFunctionResult;
	}
	return (value || 0) as FunctionalChipFunctionResult;
}
