import { DocTypes } from '@prism-frontend/typedefs/enums/doc-types';
import { PrismStrings } from '@prism-frontend/utils/static/common-user-facing-strings/interface-for-strings';

export const PRISM_STRINGS_EN_US: PrismStrings = {
	RENTAL: 'Rental',
	RENTER: 'Renter',
	TICKETED: 'Ticketed',
	NON_TICKETED: 'Non-Ticketed',
	ALL_AGES: 'All ages',
	LINK_DEAL_IMPORT_LABEL: (docType: DocTypes): string => {
		return `Click to import ${docType.toLowerCase()} into Prism`;
	},
	IMPORT_LINK_TEXT: 'this link',
	MANUAL_OVERRIDE_ARTIST_EARNINGS_WARNING:
		'Artist earnings have been manually overridden on this deal, so any changes here will not affect that value.',
	MANUAL_OVERRIDE_ARTIST_EARNINGS_DISABLED_VALUE:
		'Artist earnings have been manually overridden on this deal. Grayed out values will not be considered in the final payout number',
};
