export enum EventTicketType {
	manual = 'Manual',
	integration = 'Integration',
	hybrid = 'Hybrid',
}

export const EventTicketLabelType: Record<EventTicketType, string> = {
	Manual: 'Manual',
	Integration: 'Integrated',
	Hybrid: 'Hybrid',
};
