import { AdditionalRevenueType } from '@prism-frontend/typedefs/enums/additional-revenue-type';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { OrderedModel } from '@prism-frontend/typedefs/enums/OrderedModel';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { Orderable } from '@prism-frontend/typedefs/orderable';
import { Ticket } from '@prism-frontend/typedefs/ticket';
import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { Transform } from 'class-transformer';
import { IsBoolean, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';

export class AdditionalRevenue implements Orderable {
	public readonly modelName: OrderedModel = OrderedModel.AdditionalRevenue;

	@IsNumber() public id: string | number;

	@IsString() public name: string;

	@IsNumber()
	@Transform(castToNumber())
	public amount: number = 0;

	@IsNumber()
	@Transform(castToNumber())
	public est_amount: number = 0;

	@IsEnum(AdditionalRevenueType)
	public est_type: AdditionalRevenueType = AdditionalRevenueType.FLAT;

	/**
	 * this is a DB id (number) in events, and a UUID (string) in templates
	 */
	@IsNumber()
	@IsOptional()
	public est_ticket_id: number | string;

	@IsEnum(AdditionalRevenueType)
	public actual_type: AdditionalRevenueType = AdditionalRevenueType.FLAT;

	@IsNumber()
	@IsOptional()
	public actual_ticket_id: number;

	@IsNumber() public order: number;

	@IsBoolean()
	@Transform(castToBoolean())
	public include_in_copro_split: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public include_in_net_gross: boolean = false;

	// Set by the front end in additional-revenue.component. Unsure if the backend
	// is sending this property ever, but it is rarely relied on as of the writing
	// of this comment
	@IsNumber() public event_id?: number;

	public setTypeLabel(costCalc: CostCalc, value: string): void {
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				this.estTypeLabel = value;
				break;
			case CostCalc.Reported:
			case CostCalc.Actual:
				this.actualTypeLabel = value;
				break;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	public getTypeLabel(costCalc: CostCalc): string {
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				return this.estTypeLabel;
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.actualTypeLabel;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	// public because used in template for additional-revenue-sidebar
	public set estTypeLabel(value: string) {
		if (value.indexOf(AdditionalRevenueType.PER_TICKET_TYPE) !== -1) {
			const parts: string[] = value.split(':');
			this.est_ticket_id = !isNaN(Number(parts[1])) ? Number(parts[1]) : parts[1];
			this.est_type = AdditionalRevenueType.PER_TICKET_TYPE;
		} else {
			this.est_type = value as AdditionalRevenueType;
			this.est_ticket_id = null;
		}
	}

	// public because used in template for additional-revenue-sidebar
	public get estTypeLabel(): string {
		if (this.est_type === AdditionalRevenueType.PER_TICKET_TYPE) {
			return `${AdditionalRevenueType.PER_TICKET_TYPE}:${this.est_ticket_id}`;
		}
		return this.est_type;
	}

	private get estimatedAmount(): number {
		return +this.est_amount;
	}

	// public because used in template for additional-revenue-sidebar
	public set actualTypeLabel(value: string) {
		if (value.indexOf(AdditionalRevenueType.PER_TICKET_TYPE) !== -1) {
			const parts: string[] = value.split(':');
			this.actual_ticket_id = Number(parts[1]);
			this.actual_type = AdditionalRevenueType.PER_TICKET_TYPE;
		} else {
			this.actual_type = value as AdditionalRevenueType;
			this.actual_ticket_id = null;
		}
	}

	// public because used in template for additional-revenue-sidebar
	public get actualTypeLabel(): string {
		if (this.actual_type === AdditionalRevenueType.PER_TICKET_TYPE) {
			return `${AdditionalRevenueType.PER_TICKET_TYPE}:${this.actual_ticket_id}`;
		}
		return this.actual_type;
	}

	public getTotal(costCalc: CostCalc, external: boolean, event: PrismEvent): number {
		let additionalRevenue: number = 0;
		if (external && !this.include_in_net_gross) {
			return additionalRevenue;
		}
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				additionalRevenue = this.getEstimatedTotal(costCalc, event);
				break;
			case CostCalc.Reported:
			case CostCalc.Actual:
				additionalRevenue = this.getActualTotal(costCalc, event);
				break;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}

		return additionalRevenue;
	}

	public isPerTicketType(costCalc: CostCalc): boolean {
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				return this.est_type === AdditionalRevenueType.PER_TICKET_TYPE;
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.actual_type === AdditionalRevenueType.PER_TICKET_TYPE;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	public isForTicket(costCalc: CostCalc, ticketId: number): boolean {
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				return this.est_ticket_id === ticketId;
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.actual_ticket_id === ticketId;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	public getAmountForTicket(costCalc: CostCalc): number {
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				return this.est_amount;
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.amount;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	public getAmountForAllTicketsAndAttendees(costCalc: CostCalc): number {
		let amount: number = 0;
		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Estimated:
			case CostCalc.Potential:
				if (this.isEstimatedForAllTicketsOrAllAttendees) {
					amount = this.est_amount;
				}
				break;
			case CostCalc.Reported:
			case CostCalc.Actual:
				if (this.isActualForAllTicketsOrAllAttendees) {
					amount = this.amount;
				}
				break;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
		return amount;
	}

	private get isActualForAllTicketsOrAllAttendees(): boolean {
		return (
			this.actual_type === AdditionalRevenueType.PER_TICKET_ALL ||
			this.actual_type === AdditionalRevenueType.PER_ATTENDEE
		);
	}

	private get isEstimatedForAllTicketsOrAllAttendees(): boolean {
		return (
			this.est_type === AdditionalRevenueType.PER_TICKET_ALL ||
			this.est_type === AdditionalRevenueType.PER_ATTENDEE
		);
	}

	/**
	 * returns getTotal() if this additional revenue is included in co pro and not
	 * included in net gross, zero otherwise
	 *
	 * @param costCalc cost calc
	 * @param external external
	 * @param event current event
	 * @returns total number for this additional revenue towards co pro
	 */
	public getTotalTowardsCoPro(costCalc: CostCalc, external: boolean, event: PrismEvent): number {
		if (!this.include_in_copro_split || this.include_in_net_gross) {
			return 0;
		}
		return this.getTotal(costCalc, external, event);
	}

	private getEstimatedTotal(costCalc: CostCalc, event: PrismEvent): number {
		switch (this.est_type) {
			case AdditionalRevenueType.FLAT:
				return this.estimatedAmount;
			case AdditionalRevenueType.PER_ATTENDEE:
				return this.estimatedAmount * event.estimated_attendance;
			case AdditionalRevenueType.PER_TICKET_ALL:
				return this.estimatedAmount * event.ticketsSold(costCalc);
			case AdditionalRevenueType.PER_TICKET_TYPE: {
				const ticket: Ticket = event.tickets.find((t: Ticket): boolean => {
					return t.id === this.est_ticket_id;
				});
				if (!ticket) {
					return 0;
				}
				return this.estimatedAmount * ticket.ticketsSold(costCalc);
			}
		}
	}

	private getActualTotal(costCalc: CostCalc, event: PrismEvent): number {
		switch (this.actual_type) {
			case AdditionalRevenueType.FLAT:
				return +this.amount;
			case AdditionalRevenueType.PER_ATTENDEE:
				return +this.amount * event.actual_attendance;
			case AdditionalRevenueType.PER_TICKET_ALL:
				return +this.amount * event.ticketsSold(costCalc);
			case AdditionalRevenueType.PER_TICKET_TYPE: {
				const ticket: Ticket = event.tickets.find((t: Ticket): boolean => {
					return t.id === this.actual_ticket_id;
				});
				if (!ticket) {
					verboseDebug('ticket not found');
					return 0;
				}
				return +this.amount * ticket.ticketsSold(costCalc);
			}
		}
	}

	// getPostFix is used in EMS because there was no top-level thing to get a postfix
	// currently the postfix is only consumed in the additional revenue component, or the
	// internal settlement page.
	public getPostFix(costCalc: CostCalc, event: PrismEvent): string {
		switch (costCalc) {
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.getActualPostfix(event);
			case CostCalc.Estimated:
			case CostCalc.Budgeted:
			case CostCalc.Potential:
				return this.getEstPostfix(event);
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	private getEstPostfix(event: PrismEvent): string {
		let text: string;
		switch (this.est_type) {
			case AdditionalRevenueType.FLAT: {
				text = 'flat';
				break;
			}
			case AdditionalRevenueType.PER_ATTENDEE: {
				text = `$${this.estimatedAmount} per attendee`;
				break;
			}
			case AdditionalRevenueType.PER_TICKET_ALL: {
				text = `$${this.estimatedAmount} per ticket - all tiers`;
				break;
			}
			case AdditionalRevenueType.PER_TICKET_TYPE: {
				const ticket: Ticket = event.tickets.find((t: Ticket): boolean => {
					return t.id === this.est_ticket_id;
				});
				let name: string;
				if (!ticket) {
					name = 'deleted ticket';
				} else {
					name = ticket.name;
				}
				text = `$${this.estimatedAmount} per ticket - ${name}`;
				break;
			}
		}
		return text;
	}

	private getActualPostfix(event: PrismEvent): string {
		let text: string;
		switch (this.actual_type) {
			case AdditionalRevenueType.FLAT: {
				text = 'flat';
				break;
			}
			case AdditionalRevenueType.PER_ATTENDEE: {
				text = `$${this.amount} per attendee`;
				break;
			}
			case AdditionalRevenueType.PER_TICKET_ALL: {
				text = `$${this.amount} per ticket - all tiers`;
				break;
			}
			case AdditionalRevenueType.PER_TICKET_TYPE: {
				const ticket: Ticket = event.tickets.find((t: Ticket): boolean => {
					return t.id === this.actual_ticket_id;
				});
				let name: string;
				if (!ticket) {
					name = 'deleted ticket';
				} else {
					name = ticket.name;
				}
				text = `$${this.amount} per ticket - ${name}`;
				break;
			}
		}
		return text;
	}
}
