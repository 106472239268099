import { InvoiceOrReceipt } from '@prism-frontend/typedefs/invoice';
import { Type } from 'class-transformer';
import { IsInt, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

export class BalancePayment {
	// db fields
	@IsInt() public id: number = null;
	@IsInt() public deal_id: number = null;
	@IsInt() public invoice_id: number = null;
	@IsString() public created_at?: string;

	@IsNumber()
	public amount: number = 0;

	@IsOptional()
	@IsNumber()
	public exchange_rate_usd: number | null = null;

	// this is pulled in via the build endpoint on the event page
	@Type((): typeof InvoiceOrReceipt => {
		return InvoiceOrReceipt;
	})
	@ValidateNested()
	public invoice: InvoiceOrReceipt;
}
