import { Dictionary } from '@fullcalendar/core/internal';
import { RENDER_AS_EMPTY } from '@prism-frontend/components/ems-field/ems-field.typedefs';
import { AllSimpleFormFieldConfigs } from '@prism-frontend/components/simple-form/simple-form.typedefs';
import {
	FindCostGroupCategoryArg,
	getEMSRollupFixedCostsGroups,
	getFixedCostGroupFormFields,
	getPrismEventRollupFixedCostsGroups,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/findCostGroupByName';
import {
	FunctionalChip,
	FunctionalChipFunctionResult,
	FunctionalChipOrgData,
} from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChip';
import { FunctionalChipAdditionalData } from '@prism-frontend/pages/testing-page/components/field-sandbox/functional-chips/typedefs/FunctionalChipAdditionalData';
import { EMSFieldMetadataValueByCostCalc } from '@prism-frontend/typedefs/ems/ems-field-explainer-helpers';
import {
	CostCalcDependentValue,
	EMS,
	EMSCost,
	EMSFixedCost,
	EMSRollup,
	PrismEventRollup,
} from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs, EMSFieldsMeta } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { MathSummaryType } from '@prism-frontend/typedefs/enums/MathSummaryType';
import _ from 'lodash';

interface FindCostNameyArg extends FindCostGroupCategoryArg {
	property?: keyof EMSCost<number, string, boolean>;
}

const fixedCostPropertyCostCalc: Dictionary = {
	['quantity']: CostCalc2.ExternalBudgeted,
	['costAmount']: CostCalc2.ExternalBudgeted,
	['total']: CostCalc2.ExternalBudgeted,
	['InternalEstimated.computedTotal']: CostCalc2.InternalEstimated,
	['InternalActual.computedTotal']: CostCalc2.InternalActual,
	['ExternalReported.computedTotal']: CostCalc2.ExternalReported,
};

export const findFixedCostByName: FunctionalChip<FindCostNameyArg> = {
	identifier: 'findFixedCostByName',
	name: 'Find Fixed Cost By Name',
	fallbackValue: (): string => {
		return '';
	},
	theFunction(
		argument: FindCostNameyArg,
		_orgData: FunctionalChipOrgData,
		data: FunctionalChipAdditionalData
	): FunctionalChipFunctionResult {
		if (data.type !== MathSummaryType.PrismEventRollup && data.type !== MathSummaryType.PrismEventRollupFlat) {
			return getEMSRollupFixedCostByName(argument, data as EMSRollup);
		}
		return getPrismEventRollupFixedCostByName(argument, data as PrismEventRollup);
	},
	formFields(data: FunctionalChipOrgData, _value: FindCostNameyArg): AllSimpleFormFieldConfigs<FindCostNameyArg>[] {
		return [
			...getFixedCostGroupFormFields(data.isBroadway),
			{
				label: 'Name',
				description: 'Choose a cost name to search for',
				fieldType: 'text',
				key: 'name',
				required: true,
				editable: true,
			},
			{
				label: 'Property',
				description: 'Choose a cost property to show and edit',
				fieldType: 'select',
				key: 'property',
				required: true,
				editable: true,
				suggestions: [
					// One option per editable field in the fixed cost table
					// Notes is not present here since we don't track this over EMS
					// and we can't pull it from the EventsQuery since its not supported
					{
						// Quantity
						label: EMSFieldsMeta['fixedCosts.*.costs.*.quantity'].userFacingLabel as string,
						value: 'quantity',
					},
					{
						// Cost
						label: EMSFieldsMeta['fixedCosts.*.costs.*.costAmount'].userFacingLabel as string,
						value: 'costAmount',
					},
					{
						// External Budgeted
						label: EMSFieldsMeta['fixedCosts.*.costs.*.total'].userFacingLabel[
							CostCalc2.ExternalBudgeted as keyof EMSFieldMetadataValueByCostCalc<string>
						],
						value: 'total',
					},
					{
						// Estimated Internal
						label: EMSFieldsMeta['fixedCosts.*.costs.*.computedTotal'].userFacingLabel[
							CostCalc2.InternalEstimated as keyof EMSFieldMetadataValueByCostCalc<string>
						],
						value: 'InternalEstimated.computedTotal',
					},
					{
						// Actual Internal
						label: EMSFieldsMeta['fixedCosts.*.costs.*.computedTotal'].userFacingLabel[
							CostCalc2.InternalActual as keyof EMSFieldMetadataValueByCostCalc<string>
						],
						value: 'InternalActual.computedTotal',
					},
					{
						// Settlement
						label: EMSFieldsMeta['fixedCosts.*.costs.*.computedTotal'].userFacingLabel[
							CostCalc2.ExternalReported as keyof EMSFieldMetadataValueByCostCalc<string>
						],
						value: 'ExternalReported.computedTotal',
					},
				],
			},
		];
	},
	chipText: (value: FindCostNameyArg): string => {
		return `Find Fixed Cost by name: ${value.name} - ${value.property}`;
	},
};

function getEMSRollupFixedCostByName(argument: FindCostNameyArg, emsRollup: EMSRollup): FunctionalChipFunctionResult {
	const costCalc: CostCalc2 = fixedCostPropertyCostCalc[argument.property];
	const ems: EMS = emsRollup[costCalc];
	const matchingGroups: EMSFixedCost<number, string, boolean>[] = getEMSRollupFixedCostsGroups(ems, argument);
	const matchingCost: EMSCost<number, string, boolean> = _.chain(matchingGroups)
		.flatMap((group: EMSFixedCost<number, string, boolean>): EMSCost<number, string, boolean>[] => {
			return group.costs;
		})
		.find((cost: EMSCost<number, string, boolean>): boolean => {
			return (cost.name || '').trim().toLowerCase().includes(argument.name.toLowerCase());
		})
		.value();
	if (!matchingCost) {
		return RENDER_AS_EMPTY;
	}
	const emsProperty: string = argument.property.split('.').pop();
	return `${matchingCost.costCalc}.${matchingCost.emsPath}.${emsProperty}` as keyof EMSFieldDefs;
}

function getPrismEventRollupFixedCostByName(
	argument: FindCostNameyArg,
	prismEventRollup: PrismEventRollup
): FunctionalChipFunctionResult {
	const costCalc: CostCalc2 = fixedCostPropertyCostCalc[argument.property];
	const matchingGroups: EMSFixedCost<
		CostCalcDependentValue<number>,
		CostCalcDependentValue<string>,
		CostCalcDependentValue<boolean>
	>[] = getPrismEventRollupFixedCostsGroups(prismEventRollup, argument);
	const matchingCost: EMSCost<
		CostCalcDependentValue<number>,
		CostCalcDependentValue<string>,
		CostCalcDependentValue<boolean>
	> = _.chain(matchingGroups)
		.flatMap(
			(
				group: EMSFixedCost<
					CostCalcDependentValue<number>,
					CostCalcDependentValue<string>,
					CostCalcDependentValue<boolean>
				>
			): EMSCost<
				CostCalcDependentValue<number>,
				CostCalcDependentValue<string>,
				CostCalcDependentValue<boolean>
			>[] => {
				return group.costs;
			}
		)
		.find(
			(
				cost: EMSCost<
					CostCalcDependentValue<number>,
					CostCalcDependentValue<string>,
					CostCalcDependentValue<boolean>
				>
			): boolean => {
				return (cost.name || '').trim().toLowerCase().includes(argument.name.toLowerCase());
			}
		)
		.value();
	if (!matchingCost) {
		return RENDER_AS_EMPTY;
	}
	const emsProperty: string = argument.property.split('.').pop();
	return `${costCalc}.${matchingCost.emsPath}.${emsProperty}` as keyof EMSFieldDefs;
}
