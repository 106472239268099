<span class="icon js-prism-icon" [attr.data-icon-name]="iconAttribute">
	<ng-container *ngIf="iconName === IconName.INFO_ICON">
		<svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" [attr.fill]="fill">
			<path
				d="M4.5 3.5H5.5V2.5H4.5V3.5ZM5 9C2.795 9 1 7.205 1 5C1 2.795 2.795 1 5 1C7.205 1 9 2.795 9 5C9 7.205 7.205 9 5 9ZM5 0C4.34339 0 3.69321 0.129329 3.08658 0.380602C2.47995 0.631876 1.92876 1.00017 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C1.92876 8.99983 2.47995 9.36812 3.08658 9.6194C3.69321 9.87067 4.34339 10 5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 4.34339 9.87067 3.69321 9.6194 3.08658C9.36812 2.47995 8.99983 1.92876 8.53553 1.46447C8.07124 1.00017 7.52005 0.631876 6.91342 0.380602C6.30679 0.129329 5.65661 0 5 0ZM4.5 7.5H5.5V4.5H4.5V7.5Z"
			/>
		</svg>
	</ng-container>

	<ng-container *ngIf="iconName === IconName.LOADING_ICON">
		<!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
		<svg
			class="loading-icon"
			width="55"
			height="80"
			viewBox="0 0 55 80"
			xmlns="http://www.w3.org/2000/svg"
			[attr.fill]="fill"
		>
			<g transform="matrix(1 0 0 -1 0 80)">
				<rect width="10" height="20" rx="3">
					<animate
						attributeName="height"
						begin="0s"
						dur="8.6s"
						values="20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20"
						calcMode="linear"
						repeatCount="indefinite"
					/>
				</rect>
				<rect x="15" width="10" height="80" rx="3">
					<animate
						attributeName="height"
						begin="0s"
						dur="4s"
						values="80;55;33;5;75;23;73;33;12;14;60;80"
						calcMode="linear"
						repeatCount="indefinite"
					/>
				</rect>
				<rect x="30" width="10" height="50" rx="3">
					<animate
						attributeName="height"
						begin="0s"
						dur="2.8s"
						values="50;34;78;23;56;23;34;76;80;54;21;50"
						calcMode="linear"
						repeatCount="indefinite"
					/>
				</rect>
				<rect x="45" width="10" height="30" rx="3">
					<animate
						attributeName="height"
						begin="0s"
						dur="4s"
						values="30;45;13;80;56;72;45;76;34;23;67;30"
						calcMode="linear"
						repeatCount="indefinite"
					/>
				</rect>
			</g>
		</svg>
	</ng-container>

	<ng-container *ngIf="iconName === IconName.SORT_ICON">
		<svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				[attr.fill]="fill"
				d="M9.40985 9V7H17.8033V9H9.40985ZM9.40985 15V13H13.6066V15H9.40985ZM9.40985 3V1H22V3H9.40985ZM5.21313 13H7.83608L4.16395 16.5L0.491821 13H3.11477V0H5.21313V13Z"
			/>
		</svg>
	</ng-container>

	<ng-container *ngIf="iconName === IconName.LINKED_DEAL">
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" [attr.fill]="fill" stroke="white" />
			<path
				d="M10.2108 10.0484C9.72714 10.532 8.94083 10.532 8.45717 10.0484L7.3258 8.91701L6.7884 9.45442L7.91977 10.5858C8.70042 11.3664 9.96755 11.3664 10.7482 10.5858C11.5288 9.80514 11.5288 8.53801 10.7482 7.75736L9.61683 6.62599L9.07943 7.16339L10.2108 8.29476C10.6945 8.77842 10.6945 9.56472 10.2108 10.0484ZM9.33398 8.60589L7.07124 6.34315L6.50556 6.90883L8.7683 9.17157L9.33398 8.60589ZM5.09134 7.75736L6.22271 8.88873L6.76012 8.35133L5.62874 7.21996C5.14508 6.7363 5.14508 5.94999 5.62874 5.46633C6.11241 4.98267 6.89871 4.98267 7.38237 5.46633L8.51374 6.5977L9.05114 6.0603L7.91977 4.92893C7.13912 4.14829 5.87199 4.14829 5.09134 4.92893C4.3107 5.70958 4.3107 6.97671 5.09134 7.75736Z"
				[attr.fill]="stroke"
			/>
		</svg>
	</ng-container>
</span>
