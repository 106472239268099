import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from '@prism-frontend/modules/material.module';
import { EMS, EMSRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { eventStatusToString } from '@prism-frontend/typedefs/enums/event-status';
import { PrismEvent } from '@prism-frontend/typedefs/event';

@Component({
	selector: 'event-status-icon',
	template: `
		<span
			*ngIf="event || ems.isEMS"
			[class.color-marker]="!textOnly"
			class="color-marker-{{ eventStatus.toLowerCase() }}"
			[matTooltip]="eventStatus"
		>
			{{ textOnly ? eventStatus : '' }}
		</span>
	`,
	styleUrls: ['./event-status-icon.component.scss'],
	standalone: true,
	imports: [CommonModule, MaterialModule],
})
export class EventStatusIconComponent {
	@Input() public ems: EMS | EMSRollup;
	@Input() public event: PrismEvent;
	@Input() public textOnly: boolean = false;

	public get eventStatus(): string {
		if (this.event) {
			return eventStatusToString(this.event.confirmed, this.event);
		}

		return eventStatusToString(this.ems.eventStatus, this.ems);
	}
}
