/**
 * Return
 *
 * @param name a name, like Barack Obama or Walter White
 */

export function getInitials(name: string = ''): string {
	// get the first and last initials of the user's name
	const nameInitials: string = name
		.trim()
		.split(' ')
		.reduce((initials: string[], namePiece: string, index: number, names: string[]): string[] => {
			const shouldAddInitial: boolean = namePiece.trim().length && (index === 0 || index === names.length - 1);
			if (shouldAddInitial) {
				initials.push(namePiece.trim()[0]);
			}
			return initials;
		}, [])
		.join('');

	return nameInitials;
}
