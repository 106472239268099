export enum EventFilterOption {
	Agent = 'agent',
	Artist = 'artist',
	Capacity = 'capacity',
	Company = 'company',
	Contact = 'contact',
	ContractDueDateRange = 'contractDueDateRange',
	CostCalc = 'costCalc',
	Currencies = 'currencies',
	DateRange = 'dateRange',
	DaysOfWeek = 'daysOfWeek',
	DealStatuses = 'dealStatuses',
	EntityType = 'entityTypes',
	EventCreatedDateRange = 'eventCreatedDateRange',
	EventOwner = 'eventOwner',
	EventStatus = 'eventStatus',
	Genre = 'genre',
	Holidays = 'holidays',
	IncludeArchivedEvents = 'includeArchivedEvents',
	InvoiceCreatedDateRange = 'invoiceCreatedDateRange',
	InvoicePaidDateRange = 'invoicePaidDateRange',
	InvoiceStatuses = 'invoiceStatuses',
	InvoiceType = 'invoiceType',
	MonthsOfYear = 'monthsOfYear',
	PaymentStatuses = 'paymentStatuses',
	PaymentTypes = 'paymentTypes',
	Search = 'search',
	ShowType = 'showType',
	Sort = 'sort',
	SpotifyArtists = 'artistDeprecated',
	VenueStage = 'venueStage',
	VenuesCities = 'venuesCities',
	VenuesStates = 'venuesStates',
}

// This does NOT control the order of event filters in the sidebar.
// See the private EventFilterOrdering variable within event-filters.component.ts for that
export const AllEventFilterOptions: EventFilterOption[] = [
	EventFilterOption.Search,
	EventFilterOption.DateRange,
	EventFilterOption.EventCreatedDateRange,
	EventFilterOption.EventStatus,
	EventFilterOption.EntityType,
	EventFilterOption.ShowType,
	EventFilterOption.VenueStage,
	EventFilterOption.VenuesCities,
	EventFilterOption.VenuesStates,
	EventFilterOption.EventOwner,
	EventFilterOption.Company,
	// SpotifyArtists = Non-agency
	EventFilterOption.SpotifyArtists,
	// Artist = Agency picker–only shows agency's clients
	EventFilterOption.Artist,
	EventFilterOption.Agent,
	EventFilterOption.Contact,
	EventFilterOption.Sort,
	EventFilterOption.Genre,
	EventFilterOption.Currencies,
	// Calendar Only
	EventFilterOption.Holidays,
	// P&L Only
	EventFilterOption.CostCalc,
	// Payments table only
	EventFilterOption.PaymentTypes,
	EventFilterOption.PaymentStatuses,
	// Deal tracker only
	EventFilterOption.DealStatuses,
	// Invoice report only
	EventFilterOption.InvoiceCreatedDateRange,
	EventFilterOption.InvoicePaidDateRange,
	EventFilterOption.InvoiceStatuses,
	EventFilterOption.InvoiceType,
	EventFilterOption.IncludeArchivedEvents,
	EventFilterOption.ContractDueDateRange,
	// Data Sharing/Insights Feature only
	EventFilterOption.Capacity,
	EventFilterOption.DaysOfWeek,
	EventFilterOption.MonthsOfYear,
];
