export const PRISM_PURPLE: string = '#7f56d9';
export const PRISM_LIGHT_BLUE: string = '#5998e8';
export const EVENT_STATUS_HOLD_COLOR: string = '#eaf3d9';
export const EVENT_STATUS_CONFIRMED_COLOR: string = '#65b669';
export const EVENT_STATUS_ARCHIVED_COLOR: string = '#000000';
export const EVENT_STATUS_SETTLEMENT_COLOR: string = '#e1ecfa';
export const EVENT_STATUS_SETTLED_COLOR: string = '#64a0e7';
export const LIGHT_GRAY: string = '#efefef';
export const GRAY_1: string = '#f5f5f5';
export const GRAY_5: string = '#848484';
export const WARNING_BACKGROUND: string = '#fffaeb';
export const WARNING_COLOR: string = '#b54708';
