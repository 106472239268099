import { QueryDslQueryContainer, SearchRequest } from '@opensearch-project/opensearch/api';
import {
	ArtistFilterObject,
	ArtistListQueryObject,
	DateRangeFilterObject,
	DaysOfWeekFilterObject,
	GenreFilterObject,
	GenreListQueryObject,
	LeaderboardQueryObject,
	MonthsOfYearFilterObject,
	ResearchCapacityFilterObject,
	ResearchQueryObject,
	VenueCitiesQueryObject,
	VenueCityFilterObject,
	VenueStateFilterObject,
	VenueStateShortFilterObject,
	VenueStatesListQueryObject,
} from '@prism-frontend/pages/insights-page/insights-page-base-queries';

class OpensearchQuery {
	// max records to return
	public size: number = 25000;

	public startDate: string = '';
	public endDate: string = '';

	public cities: string[] = [];

	public states: string[] = [];

	public genres: number[] = [];

	public artists: string[] = [];
	public capacity: [number | null, number | null] = [null, null];
	public daysOfWeek: number[] = [];
	public monthsOfYear: number[] = [];

	protected buildFilterQueries(): QueryDslQueryContainer[] {
		const filters: QueryDslQueryContainer[] = [];
		if (this.artists.length > 0) {
			filters.push(new ArtistFilterObject(this.artists));
		}

		if (this.startDate && this.endDate) {
			filters.push(new DateRangeFilterObject(this.startDate, this.endDate));
		}

		if (this.cities.length > 0) {
			const cities: string[] = [];
			const states: string[] = [];
			this.cities.forEach((city: string): void => {
				const cityState: string[] = city.split(':');
				cities.push(cityState[0]);
				states.push(cityState[1]);
			});
			filters.push(new VenueCityFilterObject(cities));
			filters.push(new VenueStateShortFilterObject(states));
		}

		if (this.states.length > 0) {
			filters.push(new VenueStateFilterObject(this.states));
		}

		if (this.genres.length > 0) {
			filters.push(new GenreFilterObject(this.genres));
		}

		if (this.daysOfWeek.length > 0) {
			filters.push(new DaysOfWeekFilterObject(this.daysOfWeek));
		}
		if (this.monthsOfYear.length > 0) {
			filters.push(new MonthsOfYearFilterObject(this.monthsOfYear));
		}

		return filters;
	}
}

export class LeaderboardQuery extends OpensearchQuery {
	public getQuery(): SearchRequest {
		const mustCriteria: QueryDslQueryContainer[] = this.buildFilterQueries();
		let extraAggs: SearchRequest['body']['aggs'] = {};
		const script: string[] = [];
		if (this.capacity[0] !== null) {
			script.push(`params.avgCap >= ${this.capacity[0]}`);
		}
		if (this.capacity[1] !== null) {
			script.push(`params.avgCap <= ${this.capacity[1]}`);
		}
		if (script.length) {
			extraAggs = {
				capacity_avg_filter: {
					bucket_selector: {
						buckets_path: {
							avgCap: 'average_capacity>capacity_avg>value',
						},
						script: script.join(' && '),
					},
				},
			};
		}
		const query: SearchRequest = new LeaderboardQueryObject({ mustCriteria, extraAggs });
		return query;
	}
}

export class ResearchQuery extends OpensearchQuery {
	public getQuery(): SearchRequest {
		const filters: QueryDslQueryContainer[] = this.buildFilterQueries();
		const query: SearchRequest = new ResearchQueryObject(filters, this.size);
		return query;
	}

	protected override buildFilterQueries(): QueryDslQueryContainer[] {
		const filters: QueryDslQueryContainer[] = super.buildFilterQueries();
		filters.push(new ResearchCapacityFilterObject(this.capacity[0], this.capacity[1]));
		return filters;
	}
}

export class ArtistListQuery extends OpensearchQuery {
	public getQuery(): SearchRequest {
		const query: SearchRequest = new ArtistListQueryObject();
		return query;
	}
}

export class GenreListQuery extends OpensearchQuery {
	public getQuery(): SearchRequest {
		const query: SearchRequest = new GenreListQueryObject();
		return query;
	}
}

export class VenueCitiesListQuery extends OpensearchQuery {
	public getQuery(): SearchRequest {
		const query: SearchRequest = new VenueCitiesQueryObject();
		return query;
	}
}

export class VenueStatesListQuery extends OpensearchQuery {
	public getQuery(): SearchRequest {
		const query: SearchRequest = new VenueStatesListQueryObject();
		return query;
	}
}
