// all the different types of Math Summaries
// current inheritance tree is below:
//
// 		EMS -> SMS -> TMS
//
export enum MathSummaryType {
	PrismEventRollup = 'PrismEventRollup',
	PrismEventRollupFlat = 'PrismEventRollupFlat',
	EMS = 'EventMathSummary',
	SMS = 'SummedMathSummary',
	TMS = 'TourMathSummary',
}
