import { verboseDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';
export function sumPropOverObjects<T>(fromObjects: T[], propName: keyof T | ((element: T) => number)): number {
	const sum: number = _.sumBy(fromObjects, (thisObject: T): number => {
		let value: number;
		if (_.isFunction(propName)) {
			value = propName(thisObject);
		} else {
			value = thisObject[propName] as number;
		}
		if (!_.isNumber(value)) {
			verboseDebug(
				`sumPropOverObjects can only sum numbers: ${String(propName)} is ${typeof value}. value: ${value}`
			);
			// TODO PRSM-XXXX why do we not throw an error here...?
		}
		return Number(value);
	});
	return sum;
}

export function averagePropOverObjects<T>(fromObjects: T[], propName: keyof T): number {
	if (!fromObjects.length) {
		return 0;
	}
	return sumPropOverObjects(fromObjects, propName) / fromObjects.length;
}
