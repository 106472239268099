export enum DateRangeChoices {
	Upcoming = 'upcoming',
	ThisWeek = 'thisWeek',
	NextWeek = 'nextWeek',
	ThisMonth = 'thisMonth',
	Past = 'past',
	PastYesterday = 'pastY',
	ContractDue = 'contractDue',
	All = 'all',
	LastWeek = 'lastWeek',
	LastMonth = 'lastMonth',
	YearToDate = 'lastYear',
	YearToYesterday = 'lastYearY',
	Custom = 'custom',
}

export const AllDateRangeChoices: DateRangeChoices[] = Object.values(DateRangeChoices).map(
	(choice: string): DateRangeChoices => {
		return <DateRangeChoices>choice;
	}
);
