import { Datez } from '@prism-frontend/typedefs/datez';
import { DateRangeChoices } from '@prism-frontend/typedefs/enums/DateRangeChoices';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';

const MIDNIGHT: string = '00:00:00';
const MOMENT_TIME: string = `hh:mm:ss A`;
export const MOMENT_TIME_SHORT: string = `h:mm A`;
const MOMENT_TIME_LONG: string = `hh:mm A`;
export const MOMENT_TIME_WITH_SECONDS: string = `hh:mm:ss A`;
export const MOMENT_DATE_FORMAT: string = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT: string = 'MMMM D, YYYY';
export const DISPLAY_DATE_FORMAT_ABBREVIATED: string = 'MMM D, YYYY';
export const MOMENT_MONTH_AND_DATE_FORMAT: string = 'MMM DD';
export const MOMENT_DATE_ABBREV_FORMAT: string = 'M/DD/YYYY';
export const MOMENT_DAY_OF_WEEK_FORMAT: string = 'dddd';
export const MOMENT_DAY_OF_WEEK_ABBREV_FORMAT: string = 'ddd';
export const MOMENT_DAY_OF_WEEK_NUMBER: string = 'd';
export const MOMENT_DAY_AND_DATE_ABBREV: string = `ddd, MMM D`;
export const MOMENT_MONTH_IN_WORDS_YEAR_IN_NUMBERS: string = 'MMMM YYYY';
export const MOMENT_ABBREVIATED_MONTH_YEAR_IN_NUMBERS: string = 'MMM YYYY';
export const MOMENT_DAY_AND_DATE_WITHOUT_MONTH: string = 'DD ddd';
export const MOMENT_DATE_AT_MIDNIGHT: string = `${MOMENT_DATE_FORMAT} ${MIDNIGHT}`;
export const MOMENT_TIME_MILLITARY: string = `HH:mm:ss`;
export const MOMENT_YEAR_FORMAT: string = `YYYY`;
export const MOMENT_DATE_AND_TIME: string = `${MOMENT_DATE_FORMAT} ${MOMENT_TIME}`;
export const MOMENT_DATE_AND_MILITARY_TIME: string = `${MOMENT_DATE_FORMAT} ${MOMENT_TIME_MILLITARY}`;
export const DISPLAY_DATE_FORMAT_ABBREVIATED_AND_TIME: string = `${DISPLAY_DATE_FORMAT_ABBREVIATED} ${MOMENT_TIME_LONG}`;
export const START_OF_TIME: string = `1970-01-01 ${MIDNIGHT}`;
export const ADDITIONAL_DATE_FULL_TIME_INPUT_FORMAT: string = `${MOMENT_DATE_FORMAT} ${MOMENT_TIME_LONG}`;
const END_OF_TIME: string = `2999-01-01 ${MIDNIGHT}`;

export interface RelativeDates {
	now: moment.Moment;
	endOfToday: moment.Moment;
	startOfToday: moment.Moment;
	tomorrow: moment.Moment;
	startOfThisWeek: moment.Moment;
	endOfThisWeek: moment.Moment;
	startOfNextWeek: moment.Moment;
	endOfNextWeek: moment.Moment;
	startOfLastWeek: moment.Moment;
	endOfLastWeek: moment.Moment;
	startOfThisMonth: moment.Moment;
	endOfThisMonth: moment.Moment;
	startOfLastMonth: moment.Moment;
	endOfLastMonth: moment.Moment;
	startOfThisYear: moment.Moment;
	endOfTime: moment.Moment;
	startOfTime: moment.Moment;
	yesterday: moment.Moment;
}

// Get dates relative for now. this is useful for pre-computed date ranges like "this week"
// whose values cannot be const-ified like START_OF_TIME
export function getRelativeDates(): RelativeDates {
	const now: moment.Moment = moment();
	const endOfToday: moment.Moment = moment(now).endOf('day');
	const startOfToday: moment.Moment = moment(now).startOf('day');
	const tomorrow: moment.Moment = moment(now).add(1, 'day').startOf('day');
	const yesterday: moment.Moment = moment(now).subtract(1, 'day').endOf('day');
	const startOfThisWeek: moment.Moment = moment(now).startOf('week');
	const endOfThisWeek: moment.Moment = moment(now).endOf('week');
	const startOfNextWeek: moment.Moment = moment(endOfThisWeek).add(1, 'day');
	const endOfNextWeek: moment.Moment = moment(startOfNextWeek).endOf('week');
	const startOfLastWeek: moment.Moment = moment(now).subtract(7, 'days').startOf('week');
	const endOfLastWeek: moment.Moment = moment(startOfLastWeek).endOf('week');
	const startOfThisMonth: moment.Moment = moment(now).startOf('month');
	const endOfThisMonth: moment.Moment = moment(startOfThisMonth).endOf('month');
	const startOfLastMonth: moment.Moment = moment(now).subtract(1, 'month').startOf('month');
	const endOfLastMonth: moment.Moment = moment(startOfLastMonth).endOf('month');
	const startOfThisYear: moment.Moment = moment(now).startOf('year');
	return {
		now,
		endOfToday,
		startOfToday,
		tomorrow,
		yesterday,
		startOfThisWeek,
		endOfThisWeek,
		startOfNextWeek,
		endOfNextWeek,
		startOfLastWeek,
		endOfLastWeek,
		startOfThisMonth,
		endOfThisMonth,
		startOfLastMonth,
		endOfLastMonth,
		startOfThisYear,
		endOfTime: moment(END_OF_TIME),
		startOfTime: moment(START_OF_TIME),
	};
}

const DateRangeText: { [key in DateRangeChoices]: string } = {
	upcoming: 'Upcoming',
	thisWeek: 'This Week',
	nextWeek: 'Next Week',
	thisMonth: 'This Month',
	past: 'Past',
	pastY: 'Past',
	contractDue: 'Contract Past Due',
	all: 'All',
	custom: 'Custom',
	lastWeek: 'Last Week',
	lastMonth: 'Last Month',
	lastYear: 'Year To Date',
	lastYearY: 'Year To Date',
};

export function getDateRangeText(dateRangeChoice: DateRangeChoices): string {
	if (!DateRangeText[dateRangeChoice]) {
		throw new Error(`No text defined for ${dateRangeChoice}`);
	}
	return DateRangeText[dateRangeChoice];
}

export function getDateRangeFromChoice(choice: DateRangeChoices): string {
	const {
		now,
		startOfLastWeek,
		endOfLastWeek,
		startOfThisMonth,
		endOfThisMonth,
		startOfThisYear,
		startOfLastMonth,
		endOfLastMonth,
		yesterday,
	}: RelativeDates = getRelativeDates();

	switch (choice) {
		case DateRangeChoices.Upcoming:
			return `${formatDateRange(now, now)[0]} - Future`;
		case DateRangeChoices.ThisWeek:
			return `${formatDateRange(moment(now).startOf('week'), moment(now).endOf('week'))[0]}`;
		case DateRangeChoices.NextWeek:
			return `${
				formatDateRange(
					moment(now).endOf('week').add(1, 'd'),
					moment(now).endOf('week').add(1, 'd').endOf('week')
				)[0]
			}`;
		case DateRangeChoices.Past:
			return `Past - ${formatDateRange(now, now)[0]}`;
		case DateRangeChoices.PastYesterday:
			return `Past - ${formatDateRange(yesterday, yesterday)[0]}`;
		case DateRangeChoices.ContractDue:
			return `Past - ${formatDateRange(yesterday, yesterday)[0]}`;
		case DateRangeChoices.All:
			return `Past - Future`;
		case DateRangeChoices.LastWeek:
			const weekRange: [string, string] = formatDateRange(startOfLastWeek, endOfLastWeek);
			return weekRange[0];
		case DateRangeChoices.ThisMonth:
			const thisMonthRange: [string, string] = formatDateRange(startOfThisMonth, endOfThisMonth);
			return thisMonthRange[0];
		case DateRangeChoices.LastMonth:
			const lastMonthRange: [string, string] = formatDateRange(startOfLastMonth, endOfLastMonth);
			return lastMonthRange[0];
		case DateRangeChoices.YearToDate:
			const yearRange: [string, string] = formatDateRange(startOfThisYear, now);
			return yearRange[0];
		case DateRangeChoices.YearToYesterday:
			const yearYRange: [string, string] = formatDateRange(startOfThisYear, yesterday);
			return yearYRange[0];
		case DateRangeChoices.Custom:
			return '';
	}
	throw new Error(`DateRangeChoices ${choice} not supported`);
}

function sortEventDates(dates: Datez[]): moment.Moment[] {
	return _.chain(dates)
		.filter((date: Datez): boolean => {
			// sometimes this is null
			return !!date.date;
		})
		.map((date: Datez): moment.Moment => {
			return date.start_time ? moment(date.start_time) : moment(date.date);
		})
		.sortBy((date: moment.Moment): string => {
			return date.format(MOMENT_DATE_AND_MILITARY_TIME);
		})
		.value();
}

export function getDatePillText(dates: Datez[], eventIsConfirmed: boolean): [string, string] {
	const dateRange: moment.Moment[] = sortEventDates(dates);

	if (eventIsConfirmed && !dateRange.length) {
		return ['0 Dates', 'No Dates Selected'];
	}

	const firstDate: moment.Moment = _.first(dateRange);
	const lastDate: moment.Moment = _.last(dateRange);

	if (!eventIsConfirmed && !firstDate) {
		return ['0 Holds', 'No Dates Selected'];
	}

	const formatted: [string, string] = formatDateRange(firstDate, lastDate);

	if (eventIsConfirmed) {
		return formatted;
	}

	const holdPluralization: string = dates.length === 1 ? '' : 's';
	const holdText: string = `Hold${holdPluralization}`;
	const holdsText: string = `${dates.length} ${holdText}`;

	return [holdsText, formatted[0]];
}

export function getFormattedDateRangeForEvent(event: PrismEvent): string {
	return getFormattedDateRangeForEvents([event]);
}

export function getFormattedDateRangeForEvents(events: PrismEvent[]): string {
	const dateRange: moment.Moment[] = sortedDateRangeForEvents(events);

	// events with no dates is a MAD show
	if (!dateRange.length) {
		return 'Mutually Agreeable Date';
	}

	return formatDateRange(_.first(dateRange), _.last(dateRange))[0];
}

/**
 * given an array of events, return the range of dates that correspond to all
 * the combined, sorted datez in the given input evenrts
 *
 * @param events an array of events
 */
export function sortedDateRangeForEvents(events: PrismEvent[]): moment.Moment[] {
	return sortEventDates(
		_.chain(events)
			.map((event: PrismEvent): Datez[] => {
				return event.datez;
			})
			.flatten()
			.value()
	);
}

// this method is implicitly tested by our getDatePillText tests
// A few cases here:
// 1) first and last dates is the same (no range)
//    [Jun 10, 2020, Weekday]
// 2) if years vary (should display month for both dates, year for both dates)
//    [Jun 10, 2019 - Jun 18, 2020, Weekday - Weekday]
// 2a) years vary month is the same (edge case of above)
// 3) if month varies but year is the same (should display month in front of both dates, year once at the end)
//    [Jun 10 - Jul 18, 2020, Weekday - Weekday]
// 4) month and year is the same for both dates (should only display month once in front, year once at end)
//    [Jun 10 - 18, 2020, Weekday - Weekday]
export function formatDateRange(firstDate: moment.Moment, lastDate: moment.Moment): [string, string] {
	const firstDateSameMonthFormat: string = `MMM D`;
	const secondDateSameMonthFormat: string = `D, YYYY`;

	let leftFormat: string = DISPLAY_DATE_FORMAT_ABBREVIATED;
	let rightFormat: string = DISPLAY_DATE_FORMAT_ABBREVIATED;
	if (firstDate.format('MMM,YYYY') === lastDate.format('MMM,YYYY')) {
		leftFormat = firstDateSameMonthFormat;
		rightFormat = secondDateSameMonthFormat;
	} else if (firstDate.format('YYYY') === lastDate.format('YYYY')) {
		leftFormat = firstDateSameMonthFormat;
		rightFormat = DISPLAY_DATE_FORMAT_ABBREVIATED;
	}

	if (firstDate.isSame(lastDate)) {
		return [
			`${firstDate.format(DISPLAY_DATE_FORMAT_ABBREVIATED)}`,
			`${firstDate.format(MOMENT_DAY_OF_WEEK_FORMAT)}`,
		];
	}
	return [
		`${firstDate.format(leftFormat)} - ${lastDate.format(rightFormat)}`,
		`${firstDate.format(MOMENT_DAY_OF_WEEK_FORMAT)} - ${lastDate.format(MOMENT_DAY_OF_WEEK_FORMAT)}`,
	];
}

export function shouldDisplayTime(date: unknown): boolean {
	const momentDate: moment.Moment = moment(date);
	return !!momentDate.hours() && !!momentDate.minutes();
}

export function getCurrentDateWithoutTime(): Moment {
	return moment(moment().format(MOMENT_DATE_FORMAT));
}

/**
 * Generates an array of Moment objects, representing the range from startDate to endDate,
 * incremented by the specified unit.
 *
 * @param {Moment} startDate - The start date of the range.
 * @param {Moment} endDate - The end date of the range.
 * @param {'year' | 'month' | 'week' | 'day'} unit - The unit of time to increment by.
 *     This can be 'year', 'month', 'week', or 'day'.
 * @returns {Moment[]} An array of Moment objects representing the range from startDate to endDate,
 *     incremented by the specified unit.
 */
export function getMomentsInRange(
	startDate: Moment,
	endDate: Moment,
	unit: 'year' | 'month' | 'week' | 'day'
): Moment[] {
	// Start date rounded to the start of the specified unit
	const start: Moment = moment(startDate).startOf(unit);
	// End date rounded to the start of the specified unit
	const end: Moment = moment(endDate).startOf(unit);
	// Array to hold the resulting moments
	const moments: Moment[] = [];
	// Generate moments from start to end, incrementing by the specified unit
	while (start <= end) {
		moments.push(moment(start));
		start.add(1, unit);
	}

	return moments;
}
