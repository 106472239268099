import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogButtonRowComponentOptsBaseComponent } from '@prism-frontend/components/dialog-button-row/dialog-button-row.component';
import { fadeInAnimation, fadeOutAnimation } from '@prism-frontend/utils/animations/animations';

/**
 * ConfirmDialogContainer provides a way to easily spin up a new dialog components
 * with consistent styling. This component relies heavily on content projection
 * instead of @Inputs for the content of the dialog, so components can render any HTML
 * they want while still leaning on the styling and button rows defined in this component.
 *
 * Content Projection Slots:
 * - [dialog-header]: Projects content into the header section of the dialog.
 * - [dialog-sub-header]: Projects content into the sub-header section of the dialog.
 * - [dialog-body]: Projects content into the body section of the dialog.
 * - [dialog-extra-buttons]: Projects additional buttons into the footer button row of the dialog.
 *
 * Inputs:
 * - suppressHeader: Boolean to control the visibility of the header section.
 * - suppressButtons: Boolean to control the visibility of the button row. this is useful for text-only layouts that have some inherited gaps from <h> or <p> tags
 * - suppressGapBetweenSections: Boolean to control the visibility of the gap between sections.
 * - buttonLayout: Layout configuration for the buttons.
 * - inProgress: Boolean to indicate if an operation is in progress.
 * - disableCancel: Boolean to disable the cancel button.
 * - extraClassesCancel: Additional CSS classes for the cancel button.
 * - buttonTitleTextCancel: Title text for the cancel button.
 * - buttonTextCancel: Text for the cancel button.
 * - iconCancel: Icon for the cancel button.
 * - disableConfirm: Boolean to disable the confirm button.
 * - extraClassesConfirm: Additional CSS classes for the confirm button.
 * - buttonTitleTextConfirm: Title text for the confirm button.
 * - buttonTextConfirm: Text for the confirm button.
 * - iconConfirm: Icon for the confirm button.
 *
 * Outputs:
 * - confirm: Event emitted when the confirm button is clicked.
 * - cancel: Event emitted when the cancel button is clicked.
 */
@Component({
	selector: 'confirm-dialog-container',
	template: ` <div
		class="confirm-dialog-container"
		[@fadeIn]
		[@fadeOut]
		[class.suppress-gap]="suppressGapBetweenSections"
	>
		<div *ngIf="!suppressHeader" class="confirm-dialog-container-header">
			<ng-content select="[dialog-header]"></ng-content>
			<ng-content select="[dialog-sub-header]"></ng-content>
		</div>
		<div class="confirm-dialog-container-body">
			<ng-content select="[dialog-body]"></ng-content>
		</div>
		<dialog-button-row
			*ngIf="!suppressButtons"
			[buttonLayout]="buttonLayout"
			[inProgress]="inProgress"
			[disableCancel]="disableCancel"
			[extraClassesCancel]="extraClassesCancel"
			[buttonTitleTextCancel]="buttonTitleTextCancel"
			[buttonTextCancel]="buttonTextCancel"
			[iconCancel]="iconCancel"
			[disableConfirm]="disableConfirm"
			[extraClassesConfirm]="extraClassesConfirm"
			[buttonTitleTextConfirm]="buttonTitleTextConfirm"
			[buttonTextConfirm]="buttonTextConfirm"
			[iconConfirm]="iconConfirm"
			(confirm)="confirm.emit()"
			(cancel)="cancel.emit()"
		>
			<ng-content dialog-extra-buttons select="[dialog-extra-buttons]"></ng-content>
		</dialog-button-row>
		<div class="close-button" *ngIf="showCloseButton">
			<button mat-icon-button class="small-mat-icon" (click)="cancel.emit()" title="Close">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>`,
	styleUrls: ['./confirm-dialog-container.component.scss'],
	animations: [fadeInAnimation({ duration: 1000 }), fadeOutAnimation({ duration: 300 })],
})
export class ConfirmDialogContainerComponent extends DialogButtonRowComponentOptsBaseComponent {
	// IO which is specific to this component
	@Input() public suppressHeader: boolean = false;
	@Input() public suppressButtons: boolean = false;
	@Input() public suppressGapBetweenSections: boolean = false;

	@Input() public showCloseButton: boolean = false;

	@Output() public confirm: EventEmitter<void> = new EventEmitter<void>();
	@Output() public cancel: EventEmitter<void> = new EventEmitter<void>();
}
