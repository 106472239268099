import { HeadlinerBadgeItem } from '@prism-frontend/typedefs/headliner-badge-item';
import { plainToClass } from 'class-transformer';
import { IsNumber, IsString } from 'class-validator';

export class ArtistLeaderboard {
	public constructor(artistLeaderboard?: Partial<ArtistLeaderboard>) {
		return plainToClass(ArtistLeaderboard, artistLeaderboard);
	}

	@IsString()
	public artist: HeadlinerBadgeItem;

	@IsNumber()
	public totalGrossSales: number = 0;

	@IsNumber()
	public averageGrossSales: number = 0;

	@IsNumber()
	public averageCapacity: number = 0;

	@IsNumber()
	public eventCount: number = 0;

	@IsString()
	public spotifyId: string = '';
}
