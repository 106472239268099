import { customFieldValue, emsValue } from '@prism-frontend/typedefs/ems/ems-static-helpers/emsValue';
import { EMSCustomField, EMSRollup, PrismEventRollup } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { EMSFieldDefs } from '@prism-frontend/typedefs/ems/EMSFieldMeta';
import { isCostCalcDependentValue } from '@prism-frontend/typedefs/ems/walkPrismEventRollup';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { MathSummaryType } from '@prism-frontend/typedefs/enums/MathSummaryType';

export function emsValueFromRollup(
	emsOrPrismEventRollup: Partial<EMSRollup | PrismEventRollup>,
	emsMetadataId: keyof EMSFieldDefs,
	costCalc?: CostCalc2
): unknown {
	if (
		emsOrPrismEventRollup.type !== MathSummaryType.PrismEventRollup &&
		emsOrPrismEventRollup.type !== MathSummaryType.PrismEventRollupFlat
	) {
		return emsValue((emsOrPrismEventRollup as EMSRollup)[costCalc], emsMetadataId);
	}
	const value: unknown = emsValue(emsOrPrismEventRollup, emsMetadataId);
	if (!isCostCalcDependentValue(value)) {
		return value;
	}
	return value[costCalc];
}

export function customFieldFromRollup(
	customFieldsByKey: Record<string, EMSCustomField>,
	customFieldKey: string
): unknown {
	return customFieldValue(customFieldsByKey, customFieldKey);
}
